define('ember-share-db/services/password-reset', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sessionAccount: Ember.inject.service('session-account'),
    cs: Ember.inject.service('console'),
    requestReset(username) {
      this.get('cs').log("reset pword for " + username);
      return new Ember.RSVP.Promise((resolve, reject) => {
        $.ajax({
          type: "POST",
          url: _environment.default.serverHost + "/resetPassword",
          data: { username: username, hostURL: _environment.default.localOrigin }
        }).then(Ember.run.bind(res => {
          this.get('cs').log("success", res);
          resolve();
        })).catch(Ember.run.bind(err => {
          this.get('cs').log("error", err.responseText);
          reject(err.responseText);
        }));
      });
    },
    updatePassword(username, token, newPassword) {
      this.get('cs').log("updatePassword to " + newPassword + " with " + token + " for " + username);
      return new Ember.RSVP.Promise((resolve, reject) => {
        $.ajax({
          type: "POST",
          url: _environment.default.serverHost + "/updatePassword",
          data: { username: username, token: token, password: newPassword }
        }).then(Ember.run.bind(res => {
          this.get('cs').log("success", res);
          resolve();
        })).catch(Ember.run.bind(err => {
          this.get('cs').log("error", err);
          reject(err);
        }));
      });
    },
    checkToken(username, token) {
      this.get('cs').log("check token " + token + " for " + username);
      return new Ember.RSVP.Promise((resolve, reject) => {
        $.ajax({
          type: "POST",
          url: _environment.default.serverHost + "/checkPasswordToken",
          data: { username: username, token: token }
        }).then(Ember.run.bind(res => {
          this.get('cs').log("success", res);
          resolve();
        })).catch(Ember.run.bind(err => {
          this.get('cs').log("error", err);
          reject(err);
        }));
      });
    }
  });
});