define('ember-share-db/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      transitionToLoginRoute() {
        this.transitionToRoute('login');
      },
      transitionToDocsRoute() {
        this.transitionToRoute('documents', " ", 0, "date");
      },
      transitionToExamplesRoute() {
        this.transitionToRoute('examples', 'root');
      },
      transitionToInputsRoute() {
        this.transitionToRoute('inputs');
      },
      transitionToOutputsRoute() {
        this.transitionToRoute('outputs');
      },
      transitionToGuidesRoute() {
        this.transitionToRoute('guides', "root");
      },
      transitionToGSRoute() {
        this.transitionToRoute('getting-started', 'beginner');
      },
      transitionToAboutRoute() {
        this.transitionToRoute('about');
      },
      transitionToPeopleRoute() {
        this.transitionToRoute('people');
      },
      transitionToTermsRoute() {
        this.transitionToRoute('terms');
      },
      transitionToDoc(doc) {
        this.transitionToRoute('code-editor', doc);
      },
      transitionToUserDocs(user) {
        console.log("GETTING DOCS FOR USER:", user);
        this.transitionToRoute('documents', user, 0, "date");
      },
      transitionToGuide(guide) {
        this.transitionToRoute('guides', guide);
      },
      transitionToNewestDoc() {
        const currentUserId = this.get('sessionAccount').currentUserId;
        this.get('store').query('document', {
          filter: { search: "",
            page: 0,
            currentUser: currentUserId,
            sortBy: 'date' }
        }).then(documents => {
          this.get('sessionAccount').updateOwnedDocuments();
          this.transitionToRoute('code-editor', documents.firstObject.documentId);
        });
      }
    }
  });
});