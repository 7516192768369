define('ember-share-db/controllers/getting-started', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    url: _environment.default.localOrigin + "/images/",
    beginnerProjectUrl: _environment.default.localOrigin + "/code/a7f6b1a0-74a4-236e-f3c1-24962f45213d",
    advancedProjectUrl: _environment.default.localOrigin + "/code/5d9933c7-5c98-217b-b640-64bd9438799f",
    advancedurl: _environment.default.localOrigin + "/getting-started/advanced",
    guideurl: _environment.default.localOrigin + "/guides/root",
    isAdvanced: Ember.computed('model', function () {
      return this.get('model') == "advanced";
    })
  });
});