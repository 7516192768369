define('ember-share-db/components/example-tile', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    example: "",
    mediaQueries: Ember.inject.service(),
    description: Ember.computed('example', function () {
      return this.get('example').desc;
    }),
    isSelected: false,
    store: Ember.inject.service('store'),
    docid: Ember.computed('example', function () {
      return this.get('example').docid;
    }),
    index: 0,
    thumbnailUrl: Ember.computed('example', function () {
      return _environment.default.localOrigin + "/images/" + this.get('example').thumbnailId;
    }),
    colourId: Ember.computed('index', function () {
      return "tile" + this.get('index') % 5;
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.get('store').findRecord('document', this.get('example').docid).then(doc => {
        this.set('name', doc.get('name'));
        this.set('tags', doc.get('tags'));
      });
    },
    actions: {
      onClick() {
        this.get('onClick')(this.get("example"));
      },
      onover() {
        this.set('isSelected', true);
      },
      onout() {
        this.set('isSelected', false);
      }
    }
  });
});