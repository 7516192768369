define("ember-share-db/routes/add-cors", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    router: Ember.inject.service("router"),
    beforeModel(transition) {
      const course = transition.to.queryParams.course;
      const courseId = transition.to.queryParams.courseid;
      console.log(course, courseId);
      if (course) {
        this.router.replaceWith("course.course-unit", course, courseId);
      } else {
        // Handle case when no URL is provided
        // For example, redirect to a default route
        this.transitionTo('index');
      }
    }
  });
});