define("ember-share-db/components/console-container", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isDragging: false,
    startY: 0,
    startHeight: 0,
    moreConsole: true,
    didInsertElement() {
      this._super(...arguments);
      let parentElement = document.getElementById("resizable-console").parentNode;
      parentElement.style.width = "100%";
      parentElement.style.overflow = "scroll";
      document.getElementById("console-container").style.height = "150px";
    },
    actions: {
      mouseDown(event) {
        this.to_move = document.getElementById("console-container");
        this.isDragging = true;
        this.startY = event.clientY;
        this.startHeight = parseInt(document.defaultView.getComputedStyle(this.to_move).height, 10);
        document.addEventListener('mousemove', this.mouseMove);
        document.addEventListener('mouseup', this.mouseUp);
      },
      mouseMove(event) {
        if (this.isDragging) {
          let newHeight = this.startHeight + (this.startY - event.clientY + 10);
          this.to_move.style.height = `${newHeight}px`;
        }
      },
      mouseUp() {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.mouseMove);
        document.removeEventListener('mouseup', this.mouseUp);
      },
      mouseLeave() {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.mouseMove);
        document.removeEventListener('mouseup', this.mouseUp);
      },
      toggleConsole() {
        this.toggleProperty("moreConsole");
        var button = document.getElementById("console-button");
        var documentContainer = document.getElementById("document-container");
        if (this.get("moreConsole")) {
          button.innerHTML = "Pin Lower 📌";
          documentContainer.style.height = "96vh";
        } else {
          button.innerHTML = "Pin Higher 📌";
          documentContainer.style.height = "113vh";
        }
      }
    }
  });
});