define("ember-share-db/routes/course", ["exports", "ember-share-db/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    //   courses: inject(),
    cs: Ember.inject.service("console"),
    store: Ember.inject.service(),
    activate: function () {
      console.log("activate");
      this._super();
    },
    setupController(controller, model, transition) {
      this._super(...arguments);
      const course_unit_id = transition.to.params || {};
      //controller.set("courseUnitId", course_unit_id);

      window.scrollTo(0, 0);
    },
    afterModel: (model, transition) => {
      console.log("afterModel", model, "transition", transition);
      // reroute to overview page when URL is just course ID
      if (transition.targetName == "course.index" && sessionStorage.getItem('doRefreshGuide') == "false") {
        // transition.router.transitionTo(`/course/${model.id}/overview`, model);
        window.location.href = _environment.default.localOrigin + `/course/` + model.id + `/overview`;
      } else if (sessionStorage.getItem('doRefreshGuide') == "true" && transition.targetName == "course.index") {
        sessionStorage.setItem('doRefreshGuide', "false");
        console.log("after model", _environment.default.localOrigin + `/course/` + model.id + `/overview`);
        window.location.href = _environment.default.localOrigin + `/course/` + model.id + `/overview`;
      }
    },
    async model(params) {
      const course = await this.get("store").findRecord("course", params.course_id);

      return course;
    },
    actions: {
      didTransition() {
        // preload next course unit
        console.log("DID transition", this.currentModel);
        if (this.currentModel && this.currentModel.units && this.currentModel.units[0] && this.currentModel.units[0].id) if (this.currentModel.units[0].id) {
          this.get("store").findRecord("course-unit", this.currentModel.units[0].id);
        }
        return true; // Bubble the didTransition event
      }
    }
  });
});