define('ember-share-db/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'ember-share-db/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: `${_environment.default.oauthHost}/token`,
    serverTokenRevocationEndpoint: `${_environment.default.oauthHost}/revoke`,
    cs: Ember.inject.service('console'),
    authenticate(identification, password, scope = [], headers = {}) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('cs').log("trying to authenticate");
        const data = { 'grant_type': 'password', username: identification, password };
        const serverTokenEndpoint = this.get('serverTokenEndpoint');
        const useResponse = this.get('rejectWithResponse');
        const scopesString = Ember.makeArray(scope).join(' ');
        if (!Ember.isEmpty(scopesString)) {
          data.scope = scopesString;
        }
        this.makeRequest(serverTokenEndpoint, data, headers).then(response => {
          Ember.run(() => {
            this.get('cs').log(response);
            if (!this._validate(response)) {
              this.get('cs').log('access_token is missing in server response');
              reject('access_token is missing in server response');
            }

            const expiresAt = this._absolutizeExpirationTime(response['expires_in']);
            this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);
            if (!Ember.isEmpty(expiresAt)) {
              response = Ember.assign(response, { 'expires_at': expiresAt });
            }
            response = Ember.assign(response, { 'user_id': identification });
            resolve(response);
          });
        }, response => {
          Ember.run(null, reject, useResponse ? response : response.responseJSON || response.responseText);
        });
      });
    },
    makeRequest: function (url, data) {
      var client_id = 'application';
      var client_secret = 'secret';
      return $.ajax({
        url: this.serverTokenEndpoint,
        type: 'POST',
        data: data,
        contentType: 'application/x-www-form-urlencoded',
        headers: {
          Authorization: "Basic " + btoa(client_id + ":" + client_secret)
        }
      });
    }
  });
});