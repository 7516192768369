define('ember-share-db/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    activate() {
      this.get('cs').log('entering application route');
    },
    cs: Ember.inject.service('console'),
    sessionAccount: Ember.inject.service('session-account'),
    session: Ember.inject.service('session'),
    async beforeModel() {
      this.get('cs').log('beforeModel application route');
      await this._loadCurrentUser();
      this.get('cs').log('ending beforeModel application route');
    },
    sessionAuthenticated() {
      this._super(...arguments);
      this.get('cs').log("session authenticated");
      this._loadCurrentUser();
    },
    _loadCurrentUser() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('cs').log('loading current user');
        this.get('sessionAccount').loadCurrentUser().then(() => {
          this.get('sessionAccount').getUserFromName().then(() => {
            this.get('sessionAccount').updateOwnedDocuments().then(resolve()).catch(() => {
              this.get('cs').log('no current user');
              this.get('session').invalidate();
              resolve();
            });
          }).catch(() => {
            this.get('cs').log('no current user');
            this.get('session').invalidate();
            resolve();
          });;
        }).catch(() => {
          this.get('cs').log('no current user');
          this.get('session').invalidate();
          resolve();
        });
      });
    }
  });
});