define('ember-share-db/controllers/about', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    url: _environment.default.localOrigin + "/images",
    docURL: _environment.default.localOrigin + "/d/ /0/views",
    termsURL: _environment.default.localOrigin + "/terms",
    peopleURL: _environment.default.localOrigin + "/people",
    logoURL: _environment.default.localOrigin + "/images/logo-animation-cropped.gif",
    imlExampleURL: _environment.default.localOrigin + "/course/examples/kick-classifier",
    mediaQueries: Ember.inject.service(),
    actions: {
      refresh() {},
      openDocument(id) {}
    }
  });
});