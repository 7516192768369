define('ember-share-db/controllers/project-generator', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		store: Ember.inject.service(),
		sessionAccount: Ember.inject.service('session-account'),
		actions: {
			//Doing it this way forces the document store to be in sync
			onCreate() {
				const currentUserId = this.get('sessionAccount').currentUserId;
				this.get('store').query('document', {
					filter: { search: "",
						page: 0,
						currentUser: currentUserId,
						sortBy: 'date' }
				}).then(documents => {
					this.get('sessionAccount').updateOwnedDocuments();
					this.transitionToRoute('code-editor', documents.firstObject.documentId);
				});
			}
		}
	});
});