define("ember-share-db/adapters/course-unit", ["exports", "ember-data", "ember-share-db/models/course"], function (exports, _emberData, _course) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // import JSONAPIAdapter from "@ember-data/adapter/json-api";
  const { JSONAPIAdapter } = _emberData.default;

  class CourseAdapter extends JSONAPIAdapter {
    constructor() {
      super();
      this.namespace = "api";
    }

    buildURL(modelName, id, snapshot, requestType, query) {
      // console.log("this is the course JSON api adapter", args);

      return `https://tl2rw0ku.api.sanity.io/v2021-10-21/data/query/production?query=*%5B%20_type%20%3D%3D%20%22unit%22%20%26%26%20id.current%20%3D%3D%20%22${id}%22%5D%7B%0A%20%20id%2C%0A%20%20description%2C%0A%20%20contents%2C%0A%20%20title%2C%0A%7D`;
      // return `${super.buildURL(...args)}.json`;
    }

    handleResponse(status, headers, payload, requestData) {
      if (status !== 200) throw Error("request for course-unit API failed");

      const result = payload.result[0];

      return {
        data: {
          type: "course-unit",
          id: result.id.current,
          attributes: {
            title: result.title,
            description: result.description,
            contents: result.contents
          }
        }
      };
    }
  }
  exports.default = CourseAdapter;
});