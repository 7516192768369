define("ember-share-db/components/video-embed", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    embedSrcUrl: Ember.computed("videoEmbed", function () {
      console.log("this.videoSource", this.videoSource);
      switch (this.videoSource) {
        case "panopto":
          return `https://ual.cloud.panopto.eu/Panopto/Pages/Embed.aspx?id=${this.videoId}&autoplay=false&offerviewer=false&showtitle=false&showbrand=false&captions=false&interactivity=none`;
        case "youtube":
          return `https://www.youtube.com/embed/${this.videoId}`;
        default:
          return "";
      }
    }),
    didInsertElement() {
      this._super(...arguments);
      var video = document.getElementById(this.videoId);
      console.log("didInsertElement", video.contentWindow);
      video.setAttribute("src", this.embedSrcUrl);
      // video.contentWindow.eval(this.embedSrcUrl);

      // var xhr = new XMLHttpRequest();
      // xhr.open('GET', this.embedSrcUrl);
      // xhr.onreadystatechange = handler;
      // xhr.responseType = 'blob';
      // xhr.setRequestHeader('Cross-Origin-Embedder-Policy', "unsafe-none");
      // xhr.setRequestHeader('Access-Control-Allow-Origin', "*");
      // xhr.setRequestHeader('Cross-Origin-Resource-Policy', "cross-origin");
      // xhr.send();
      // function handler() {
      //   console.log("response", this.response);
      //   if (this.status === 200) {
      //     // this.response is a Blob, because we set responseType above
      //     var data_url = URL.createObjectURL(this.response);
      //     document.querySelector('#' + this.videoId).src = data_url;
      //   } else {
      //     console.error('no pdf :(');
      //   }
      // }
    }
  });
});