define('ember-share-db/components/main-navigation', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    session: Ember.inject.service('session'),
    documentService: Ember.inject.service('documents'),
    mediaQueries: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    namesService: Ember.inject.service('name-generator'),
    store: Ember.inject.service('store'),
    cs: Ember.inject.service('console'),
    logoURL: _environment.default.localOrigin + "/images/logo-animation-cropped.gif",
    url: _environment.default.localOrigin,
    ownedDocuments: Ember.computed('sessionAccount.ownedDocuments', function () {
      return this.get('sessionAccount').ownedDocuments;
    }),
    guides: Ember.inject.service(),
    actions: {
      createDoc() {
        const src = this.get('documentService').getDefaultSource();
        const data = { name: this.get("namesService").getName(), isPrivate: false, source: src };
        this.get('documentService').makeNewDoc(data).then(() => {
          this.get('onCreateDoc')();
        });
      },
      allDocs() {
        this.get('cs').log(this.get('sessionAccount').currentUserName);
        this.get('openUserDocs')(this.get('sessionAccount').currentUserName);
      },
      login() {
        this.get('onLogin')();
      },
      logout() {
        this.get('session').invalidate();
      },
      docs() {
        this.get('onDocs')();
      },
      about() {
        this.get('onAbout')();
      },
      gettingStarted() {
        this.get('onGettingStarted')();
      },
      people() {
        this.get('onPeople')();
      },
      examples() {
        this.get('onExamples')();
      },
      inputs() {
        this.get('onInputs')();
      },
      projects() {
        this.get('onProjects')();
      },
      outputs() {
        this.get('onOutputs')();
      },
      guides() {
        this.get('onGuides')();
      },
      openDoc(doc) {
        this.get('cs').log(doc);
        this.get('openDoc')(doc);
      },
      openGuide(guide) {
        this.get('cs').log(guide);
        this.get('openGuide')(guide);
      }
    }
  });
});