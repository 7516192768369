define("ember-share-db/routes/getting-started", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    router: Ember.inject.service(),
    model(params) {
      this.router.transitionTo("course", "mimic-reference");
    }
  });
});