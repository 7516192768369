define('ember-share-db/services/code-parsing', ['exports', 'acorn', 'acorn-walk/dist/walk', 'ember-share-db/config/environment', 'highlight.js', 'js-beautify'], function (exports, _acorn, _walk, _environment, _highlight, _jsBeautify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    cs: Ember.inject.service('console'),
    assetService: Ember.inject.service('assets'),
    sessionAccount: Ember.inject.service('session-account'),
    library: Ember.inject.service(),
    script: "",
    savedVals: null,
    hasPVals: false,
    parser: Ember.computed(() => {
      return new DOMParser();
    }),
    formatCode(code, lang = "custom-htmlmixed") {
      console.log("formatCode", lang);
      const options = {
        indent_size: 2,
        wrap_line_length: 0,
        preserve_newlines: true,
        max_preserve_newlines: 2
      };
      if (lang == "custom-javascript") {
        return (0, _jsBeautify.default)(code, options);
      }
      return _jsBeautify.default.html(code, options);
    },
    insertLibrary(lib, source) {
      let insertAfter = "<head>";
      let index = source.indexOf(insertAfter) + insertAfter.length;
      let library = this.get("library").libraryEntry(lib);
      let insert = "";
      let ops = [];
      if (!Ember.isEmpty(library.url)) {
        let url = library.url;
        if (!url.includes("http")) {
          url = _environment.default.localOrigin + "/libs/" + url;
        }
        insert = "\n <script src = \"" + url + "\"></script>";
        ops = [{ p: ["source", index], si: insert }];
      }

      if (!Ember.isEmpty(library.tabName)) {
        insert = "\n <script src = \"" + library.tabName + "\"></script>";
        ops.push({ p: ["source", index], si: insert });
      }
      let snippet = library.snippet;
      if (!Ember.isEmpty(snippet)) {
        insertAfter = "<body>";
        index = source.indexOf(insertAfter) + insertAfter.length + insert.length;
        insert = "\n" + snippet + "\n";
        ops.push({ p: ["source", index], si: insert });
      }
      return ops;
    },
    insertStyleSheets(source, children) {
      let searchIndex = 0,
          index = 0,
          ptr = 0,
          prevEnd = 0;
      let linkStartIndex = 0,
          tagStartIndex = 0;
      let searchStrs = ["<link", "/>"];
      let preamble = "",
          tag = "";
      let newSrc = "";
      let found = false;
      while ((index = source.indexOf(searchStrs[ptr], searchIndex)) > -1) {
        if (ptr == 0) {
          this.get('cs').log("found start of <link");
          searchIndex = index;
          tagStartIndex = searchIndex;
          preamble = source.substring(prevEnd, searchIndex);
        } else if (ptr == 1) {
          searchIndex = index + searchStrs[ptr].length;
          linkStartIndex = searchIndex;
          tag = source.substring(tagStartIndex, searchIndex);
          found = true;
          this.get('cs').log(tag);
          searchIndex = index + searchStrs[ptr].length;
          newSrc = newSrc + preamble;
          let added = false;
          const parsedTag = this.get('parser').parseFromString(tag, "application/xml");
          const attr = parsedTag.documentElement.attributes;
          let styleSheet = false;
          let media;
          this.get('cs').log("stylesheet", attr);
          for (let i = 0; i < attr.length; i++) {
            if (attr[i].nodeName == "rel" && attr[i].nodeValue == "stylesheet") {
              styleSheet = true;
            } else if (attr[i].nodeName == "media") {
              media = attr[i].nodeValue;
            }
          }
          if (styleSheet) {
            this.get('cs').log("stylesheet", children);
            for (let i = 0; i < attr.length; i++) {
              if (attr[i].nodeName == "href") {
                for (let j = 0; j < children.length; j++) {
                  if (children[j].name == attr[i].nodeValue) {
                    newSrc = newSrc + "<style type = \"text/css\" ";
                    if (media) {
                      newSrc = newSrc + "media = \"" + media + "\"";
                    }
                    newSrc = newSrc + ">\n";
                    newSrc = newSrc + children[j].source;
                    newSrc = newSrc + "\n</style>";
                    added = true;
                    //this.get('cs').log(newSrc);
                    break;
                  }
                }
                break;
              }
            }
          }
          if (!added) {
            newSrc = newSrc + tag;
          }
          prevEnd = searchIndex;
        }
        ptr = (ptr + 1) % searchStrs.length;
      }
      if (found) {
        newSrc = newSrc + source.substr(prevEnd);
      } else {
        newSrc = source;
      }
      return newSrc;
    },
    insertRecording(src, recordingOptions) {
      let newSrc = src;
      if (recordingOptions.isRecording && !Ember.isEmpty(recordingOptions.node.variable)) {
        newSrc = "";
        const top = src.includes("<body>") ? "<body>" : "<head>";
        let topIndex = src.indexOf(top);
        if (topIndex > 0) {
          topIndex += 6;
          newSrc = newSrc + src.substring(0, topIndex);
          newSrc = newSrc + "\n<script src = \"" + _environment.default.localOrigin + "/libs/recorder-wrapper.js\"></script>";
        } else {
          topIndex = 0;
        }
        const end = src.includes("</body>") ? "</body>" : "</head>";
        let endIndex = src.indexOf(end);
        if (endIndex > 0) {
          newSrc = newSrc + src.substring(topIndex, endIndex);
          let node = recordingOptions.node.variable;
          if (recordingOptions.node.library === "maximilian") {
            node = node + ".maxiAudioProcessor";
          } else if (recordingOptions.node.library === "MaxiInstruments") {
            node = node + ".node";
          }
          if (!Ember.isEmpty(node)) {
            newSrc = newSrc + "\n<script language=\"javascript\" type=\"text/javascript\">";
            newSrc = newSrc + "\nconst onRecordLoad = ()=>{initRecorder(" + node + ")}";
            newSrc = newSrc + "\n</script>\n";
          }
          newSrc = newSrc + src.substring(endIndex);
        }
      }
      //this.get('cs').log(newSrc)
      return newSrc;
    },
    insertTabs(src, children, assets) {
      let newSrc = "";
      const scripts = this.getScripts(src, true);
      scripts.forEach(script => {
        newSrc = newSrc + this.insertStyleSheets(script.preamble, children);
        let added = false;
        if (script.src.length == 0) {
          const parsedTag = this.get('parser').parseFromString(script.scriptTag + "</script>", "application/xml");
          const attr = parsedTag.documentElement.attributes;
          for (let i = 0; i < attr.length; i++) {
            //this.get('cs').log(attr[i].nodeName)
            if (attr[i].nodeName == "src") {
              for (let j = 0; j < children.length; j++) {
                //this.get('cs').log(children[j].data.name, attr[i].nodeValue)
                if (children[j].name == attr[i].nodeValue) {
                  newSrc = newSrc + "<script language=\"javascript\" type=\"text/javascript\">\n";
                  newSrc = newSrc + children[j].source;
                  added = true;
                  break;
                }
              }
              break;
            }
          }
        }
        //If not a script imported from a tab
        if (!added) {
          /**
          Insert crossorigin at the beginning of script tags that ARE NOT
          tabs (e.g. actual URLS to externally hosted libraries)
          This is necsesary because all external resources MUST have a CORS
          or CORP policy, and if we dont explictly put in the "crossorigin"
          atrribute, even if the resource has "Access-Control-Allow-Origin:*", it
          doesnt get past.
          */
          this.get("cs").log("not added");
          let scriptTag = script.scriptTag;
          if ((scriptTag.includes("src=") || scriptTag.includes("src =")) && !scriptTag.includes("mimicproject.com")) {
            let toFind = /<script /g;
            let replace = "<script crossorigin ";
            scriptTag = scriptTag.replace(toFind, replace);
          }

          newSrc = newSrc + scriptTag;
          let js = script.src;
          for (let j = 0; j < children.length; j++) {
            const child = children[j];
            const url = _environment.default.redirectServerHost + "/source/" + child.documentId;
            this.get('cs').log("regex for", child.name, child.documentId);
            js = js.replace(new RegExp("\"" + child.name + "\"", "gm"), "\"" + url + "\"");
            js = js.replace(new RegExp("\'" + child.name + "\'", "gm"), "\"" + url + "\"");
            //this.get('cs').log("AFTER", js);
          };
          newSrc = newSrc + js;
        }
        newSrc = newSrc + this.insertStyleSheets(script.post, children);
      });
      if (scripts.length == 0) {
        newSrc = src;
        for (let j = 0; j < children.length; j++) {
          const child = children[j];
          const url = _environment.default.redirectServerHost + "/source/" + child.documentId;
          this.get('cs').log("regex for", child.name, child.documentId);
          newSrc = newSrc.replace(new RegExp("\"" + child.name + "\"", "gm"), "\"" + url + "\"");
          newSrc = newSrc.replace(new RegExp("\'" + child.name + "\'", "gm"), "\"" + url + "\"");
          //this.get('cs').log("AFTER", js);
        };
        newSrc = this.insertStyleSheets(newSrc, children);
      }
      return newSrc;
    },
    insertDatasetId(src, docId) {
      const toFind = /new Learner\(\)/g;
      const replace = "new Learner(\"" + docId + "\")";
      const newSrc = src.replace(toFind, replace);
      return newSrc;
    },
    /**
      We swap out any doc.ac.uk hosted rapidLib libraries for the same-origin
      mimicproject.com hosted one because of CORS
    */
    replaceNoCORSResources(src) {
      //return src
      let toFind,
          replace,
          newSrc = "";
      toFind = /"https:\/\/doc.gold.ac.uk\/eavi\/rapidmix\/RapidLib.js"/g;
      replace = "\"https:\/\/mimicproject.com\/libs\/rapidLib.js\"";
      src = src.replace(toFind, replace);
      toFind = /"https:\/\/www.doc.gold.ac.uk\/eavi\/rapidmix\/RapidLib.js"/g;
      replace = "\"https:\/\/mimicproject.com\/libs\/rapidLib.js\"";
      src = src.replace(toFind, replace);
      return src;
    },
    getPossibleNodes(src) {
      const scripts = this.getScripts(src);
      let possibles = [];
      for (let i = 0; i < scripts.length; i++) {
        const script = scripts[i];
        try {
          _walk.default.simple(_acorn.default.parse(script.src), {
            VariableDeclaration: node => {
              node.declarations.forEach(dec => {
                let name = dec.id.name;
                if (!name) {
                  name = script.src.substring(dec.id.start, dec.id.end);
                }
                const init = dec.init;
                let exp = script.src.substring(dec.start, dec.end);
                if (!Ember.isEmpty(init)) {
                  if (init.type === "NewExpression" && exp.includes("maxiAudio(")) {
                    possibles.push({ library: "maximilian", variable: name });
                  } else if (init.type === "NewExpression" && exp.includes("MaxiInstruments(")) {
                    possibles.push({ library: "MaxiInstruments", variable: name });
                  } else if (init.type === "NewExpression" && exp.includes("Node(")) {
                    possibles.push({ library: "WebAudio", variable: name });
                  } else if (init.type === "CallExpression" && init.callee.property !== undefined) {
                    const webAudioFactories = ["createOscillator", "createBufferSource", "createMediaElementSource", "createBiquadFilter", "createMediaStreamTrackSource", "createDelay", "createDynamicsCompressor", "createGain", "createPeriodicWave"];
                    if (webAudioFactories.some(e => e === init.callee.property.name)) {
                      possibles.push({ library: "WebAudio", variable: name });
                    }
                  }
                }
              });
            }
          });
        } catch (err) {
          //this.get('cs').log(err);
        }
      }
      this.get('cs').log("possibles", possibles);
      return possibles;
    },
    insertConsoleCallbacks(src, savedVals) {
      let newSrc = "";
      this.set('savedVals', savedVals);
      this.set('hasPVals', false);
      let didEdit = false;
      this.get('cs').log("inserting stateful callbacks");
      const scripts = this.getScripts(src, false);
      for (let i = 0; i < scripts.length; i++) {
        const script = scripts[i];
        newSrc = newSrc + script.preamble;
        let ops = [];
        let added = false;
        try {
          _walk.default.simple(_acorn.default.parse(script.src), {
            CallExpression: node => {
              if (!Ember.isEmpty(node.callee.object)) {
                if (node.callee.object.name === "console") {
                  let output = "";

                  for (let j = 0; j < node.arguments.length; j++) {
                    const arg = node.arguments[j];
                    const val = script.src.substring(arg.start, arg.end);
                    let delim = j < node.arguments.length - 1 ? "," : "";
                    output = output + "prettyPrint(" + val + ")" + delim;
                    this.get('cs').log("adding in console statement", arg, val);
                  }
                  const msg = "\nparent.postMessage([\"console\"," + output + "], \"*\");";
                  let index = node.end;
                  const end = script.src.substring(index, index + 1);
                  if (end == ";") {
                    index++;
                  }
                  ops.push({ si: msg, p: index });
                  this.get('cs').log("op pushed", ops.length);
                }
              }
            }
          });
        } catch (err) {
          this.get('cs').log("acorn couldnt parse script, probably src", err);
        }
        if (ops.length > 0) {
          let offset = 0;
          let newScript = script.src;
          for (let j = 0; j < ops.length; j++) {
            didEdit = true;
            if (ops[j].si) {
              const str = ops[j].si;
              const index = ops[j].p + offset;
              newScript = newScript.slice(0, index) + str + newScript.slice(index);
              offset += str.length;
            } else if (ops[j].sd) {
              const len = ops[j].sd.length;
              const index = ops[j].p + offset;
              newScript = newScript.slice(0, index) + newScript.slice(index + len);
              offset -= len;
            }
          }
          added = true;
          newSrc = newSrc + script.scriptTag;
          newSrc = newSrc + newScript;
        }
        if (!added) {
          newSrc = newSrc + script.scriptTag;
          newSrc = newSrc + script.src;
        }
        newSrc = newSrc + script.post;
      }
      // this.get('cs').log("SOURCE", newSrc);
      return didEdit ? newSrc : src;
    },
    getScripts(source, addCallbacks = false) {
      let searchIndex = 0,
          index = 0,
          ptr = 0,
          prevEnd = 0;
      let scriptStartIndex = 0,
          tagStartIndex = 0;
      let searchStrs = ['<script', ">", "</script>"];
      let scripts = [];
      let preamble = "",
          scriptTag = "";
      while ((index = source.indexOf(searchStrs[ptr], searchIndex)) > -1) {
        if (ptr == 0) {
          searchIndex = index;
          tagStartIndex = searchIndex;
          preamble = source.substring(prevEnd, searchIndex);
        } else if (ptr == 1) {
          searchIndex = index + searchStrs[ptr].length;
          scriptStartIndex = searchIndex;
          scriptTag = source.substring(tagStartIndex, searchIndex);
        } else if (ptr == 2) {
          searchIndex = index + searchStrs[ptr].length;
          const src = scriptStartIndex <= index - 1 ? source.substring(scriptStartIndex, index - 1) : "";
          scripts.push({
            preamble: preamble,
            scriptTag: scriptTag,
            src: src,
            post: "\n</script>"
          });
          prevEnd = searchIndex;
        }
        ptr = (ptr + 1) % searchStrs.length;
      }

      if (scripts.length > 0 && addCallbacks) {
        //Add in error callback (prints errors and console logs back to mimic console)
        //The spaces are necessary at the end because 1 character gets trimmed off somewhere?
        scripts[0].preamble = scripts[0].preamble + `
      <script>
        window.onerror = function(error, url, line) {
          parent.postMessage([\"console\", \"\\"Error on line[\" + (line-1) + \"]:\" + error + \"\\"\"], \"*\")
        };
        window.addEventListener("unhandledrejection", function (event) {
          parent.postMessage([\"console\", \"\\"Error:Uncaught \" + event.reason + \"\\"\"], \"*\")
        });
        function prettyPrint(obj, indent = 0, seen = new Map()) {
          
          let result = '';
          let baseIndent = ' '.repeat(indent);
          let newIndent = baseIndent + '    ';
          try {
          if (seen.has(obj)) {
              return \`\${baseIndent}"[Circular Reference (\${seen.get(obj)})]"\`;
          }
      
          if (typeof obj === 'object' && obj !== null) {
              seen.set(obj, seen.size + 1);
      
              if (Array.isArray(obj)) {
                  result += '[';
                  for (let i = 0; i < obj.length; i++) {
                      if (i > 0) {
                          result += ',';
                      }
                      const value = obj[i];
                      if (typeof value === 'object' && value !== null) {
                          result += '\\n' + prettyPrint(value, indent + 4, seen);
                      } else if (typeof value === 'string') {
                          result += \`\\n\${newIndent}"\${value}"\`;
                      } else if (typeof value === 'function') {
                        result += \`\\n\${newIndent}\"[Function]"\`;
                      }  else if (value === undefined) {
                        result += \`\\n\${newIndent}\"[undefined]"\`;
                      }
                      else {
                        result += \`\\n\${newIndent}\${value}\`;
                      }
                  }
                  result += \`\\n\${baseIndent}]\`;
              } else {
                  result += '{\\n';
                  let entries = Object.keys(obj).map((key) => {
                      const value = obj[key];
                      let entry = \`\${newIndent}"\${key}": \`;
                      if (typeof value === 'object' && value !== null) {
                          entry += prettyPrint(value, indent + 4, seen);
                      } else if (typeof value === 'string') {
                          entry += \`"\${value}"\`;
                      } else if (typeof value === 'function') {
                        entry += \`\\n\${newIndent}\"[Function]"\`;
                      } else if (value === undefined) {
                        entry += \`\\n\${newIndent}\"[undefined]"\`;
                      } else {
                          entry += value;
                      }
      
                      return entry;
                  });
      
                  result += entries.join(',\\n');
                  result += \`\\n\${baseIndent}}\`;
              }
          } else {
              if (typeof obj === 'string') {
                  result += \`"\${obj}"\`;
              } else {
                  result += obj.toString();
              }
          }
        } catch (err) {
          result = "error making json string"
        }
      
          return result;
      }
        </script>`;
        scripts[scripts.length - 1].post = scripts[scripts.length - 1].post + source.substr(prevEnd);
      }
      return scripts;
    },
    replaceAssets(source, assets, docId) {
      //this.get('cs').log("ORIGINAL", source)
      return new Ember.RSVP.Promise((resolve, reject) => {
        const replaceAll = async () => {
          for (let i = 0; i < assets.length; i++) {
            const fileId = assets[i].fileId;
            const toFind = assets[i].name;
            const fileType = assets[i].fileType;
            let asset = this.get('store').peekRecord('asset', fileId);

            //this.get('cs').log("replaceAssets",assets[i].size)
            const useBase64 = true;
            //If file is media replace with base64
            if (this.get('assetService').isMedia(fileType) && !this.get('assetService').isTooBig(assets[i].size) && useBase64) {
              if (!Ember.isEmpty(asset)) {
                const b64 = "data:" + fileType + ";charset=utf-8;base64," + asset.b64data;
                //this.get('cs').log("replaced base64")
                source = source.replace(new RegExp(toFind, "gm"), b64);
              } else {
                //this.get('cs').log("need to fetch asset for conversion");
                await this.get('assetService').fetchAsset(assets[i], docId);
                //this.get('cs').log("finding record");
                asset = this.get('store').peekRecord('asset', fileId);
                //this.get('cs').log("found record");
                const b64 = "data:" + fileType + ";charset=utf-8;base64," + asset.b64data;
                source = source.replace(new RegExp(toFind, "gm"), b64);
                //  this.get('cs').log("replaced base64")
              }
            } else {
              //Else just use endpoint
              const url = _environment.default.serverHost + "/asset/" + docId + "/" + toFind;
              //this.get('cs').log("replaced url", url)
              source = source.replace(new RegExp("\"" + toFind + "\"", "gm"), "\"" + url + "\"");
              source = source.replace(new RegExp("\'" + toFind + "\'", "gm"), "\"" + url + "\"");
              //this.get('cs').log(source)
            }
          }
          resolve(source);
        };
        replaceAll();
      });
    },
    /*
    We have rolled our own because the code mirror implementation
    (doc.indexFromPos) return incorrect values for {} when auto indented
    ALSO:Multi line undo error
    When you tab or shift tab multi lines, then undo we get bulked operations
    occuring with lines coming from bottom to top, this causes issues with
    "getLine()" its measuring lines in a doc post change (doesnt effect us top
    to bottom as it never reaches the lines below itself). This is fixed by sorting
    ops by line before
    */
    indexFromPos(pos, editor) {
      let index = 0;
      for (let i = 0; i < pos.line; i++) {
        //+ 1 for \n
        index += editor.getDoc().getLine(i).length + 1;
      }
      return index + pos.ch;
    },
    addOp(delta, editor) {
      const op = {};
      const start = this.indexFromPos(delta.from, editor);
      op.p = ['source', start];
      const str = delta.text.join('\n');
      op['si'] = str;
      op.owner = this.get('sessionAccount').currentUserName;
      op.cursor = editor.doc.getCursor();
      op.date = new Date().getTime();
      //this.get('cs').log("delta op", op);
      return op;
    },
    removeOp(delta, editor) {
      const op = {};
      const start = this.indexFromPos(delta.from, editor);
      op.p = ['source', start];
      const str = delta.removed.join('\n');
      op['sd'] = str;
      op.owner = this.get('sessionAccount').currentUserName;
      op.cursor = editor.doc.getCursor();
      op.date = new Date().getTime();
      //this.get('cs').log("delta op", op);
      return op;
    },
    getOps(delta, editor) {
      let ops = [];
      const compare = (a, b) => {
        if (a.from.line < b.from.line) {
          return -1;
        }
        if (a.from.line > b.from.line) {
          return 1;
        }
        return 0;
      };
      //Sort by line to avoid errors with undo (see explanation in comment by indexFromPos)
      delta = delta.sort(compare);
      delta.forEach(change => {
        if (change.origin === "playback") {
          this.get('cs').log("ignoring change");
          return ops;
        }
        if (change.removed[0].length > 0 && change.removed.length === 1 || change.removed.length > 1) {
          ops.push(this.removeOp(change, editor));
        }
        if (change.text[0].length > 0 && change.text.length === 1 || change.text.length > 1) {
          ops.push(this.addOp(change, editor));
        }
      });

      return ops;
    },
    applyOps: function (ops, editor) {
      let opToDelta = op => {
        const start = op.p[op.p.length - 1];
        const from = editor.doc.posFromIndex(start);
        if ('sd' in op) {
          const end = start + op.sd.length;
          const to = editor.doc.posFromIndex(end);
          this.get("cs").log("deleting", from, to);
          editor.doc.replaceRange("", from, to, "playback");
        } else if ('si' in op) {
          this.get("cs").log("adding", op.si);
          editor.doc.replaceRange(op.si, from, null, "playback");
        } else {
          throw new Error(`Invalid Operation: ${JSON.stringify(op)}`);
        }
      };
      ops.forEach(op => {
        opToDelta(op);
      });
      editor.refresh();
    },
    getLanguage(source) {
      let highlightResult = _highlight.default.highlightAuto(source, ["css", "javascript"]);
      //this.get('cs').log("language", highlightResult.language);
      return highlightResult.language;
    }
  });
});