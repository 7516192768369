define('ember-share-db/components/page-picker-paginator', ['exports', 'ember-railio-grid/components/page-picker-paginator'], function (exports, _pagePickerPaginator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pagePickerPaginator.default;
    }
  });
});