define("ember-share-db/adapters/course", ["exports", "ember-data", "ember-share-db/models/course"], function (exports, _emberData, _course) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // import JSONAPIAdapter from "@ember-data/adapter/json-api";
  const { JSONAPIAdapter } = _emberData.default;

  function normalizeUnit({ course, unit }) {
    return {
      id: unit.id.current,
      course_id: course.id.current,
      title: unit.title,
      author: unit.author,
      description: unit.description
    };
  }

  function normalizeCourse({ course }) {
    return {
      type: "course",
      id: course.id.current,
      attributes: {
        author: course.author,
        title: course.title,
        description: course.description,
        summary: course.summary,
        prerequisites: course.prerequisites,
        units: course.units && course.units.map(unit => normalizeUnit({ course, unit })),
        "poster-image": course.posterImage,
        "is-published": !!course.isPublished,
        "published-at": course.publishedAt
      }
    };
  }

  class CourseAdapter extends JSONAPIAdapter {
    constructor() {
      super();
      this.namespace = "api";
    }

    urlForFindAll(...args) {
      console.log("this is the course JSON api adapter", args);

      return "https://tl2rw0ku.api.sanity.io/v2021-10-21/data/query/production?query=*%5B%20_type%20%3D%3D%20%22course%22%5D%7B%0A%20%20id%2C%0A%20%20title%2C%0A%20%20author%2C%0A%20%20posterImage%2C%0A%20%20description%2C%0A%20%20summary%2C%0A%20%20prerequisites%2C%0A%20%20units%5B%5D-%3E%7B%0A%20%20%20%20id%2C%0A%20%20%20%20author%2C%0A%20%20%20%20description%2C%0A%20%20%20%20title%2C%0A%20%20%7D%2C%0A%20%20publishedAt%2C%0A%20%20isPublished%2C%0A%20%20%20%20%0A%7D";
      // return `${super.buildURL(...args)}.json`;
    }

    urlForFindRecord(id, modelName, snapshot) {
      return `https://tl2rw0ku.api.sanity.io/v2021-10-21/data/query/production?query=*%5B%20_type%20%3D%3D%20%22course%22%20%26%26%20id.current%20%3D%3D%20%22${id}%22%5D%7B%0A%20%20id%2C%0A%20%20title%2C%0A%20%20author%2C%0A%20%20posterImage%2C%0A%20%20description%2C%0A%20%20summary%2C%0A%20%20prerequisites%2C%0A%20%20units%5B%5D-%3E%7B%0A%20%20%20%20id%2C%0A%20%20%20%20author%2C%0A%20%20%20%20description%2C%0A%20%20%20%20title%2C%0A%20%20%7D%2C%0A%20%20publishedAt%2C%0A%20%20isPublished%2C%0A%20%20%20%20%0A%7D`;
    }

    handleResponse(status, headers, payload, requestData) {
      if (status !== 200) throw Error("request for courses API failed");

      const { query } = payload;

      // Hack: id.current in query means were searching for single record
      if (query.includes("id.current")) {
        const result = payload.result[0];
        const course = normalizeCourse({ course: result });
        return { data: course };
      }

      // Otherwise, return all results
      const results = payload.result.map(course => {
        return normalizeCourse({ course });
      });

      return {
        data: results
      };
    }
  }
  exports.default = CourseAdapter;
});