define("ember-share-db/components/loading-hud", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    message: "Loading..",
    hideWheel: false
  });
});