define('ember-share-db/routes/code-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    cs: Ember.inject.service('console'),
    queryParams: {
      showCode: {
        replace: true
      },
      embed: {
        replace: true
      },
      colab: {
        replace: true
      }
    },
    cors: Ember.inject.service("cors"),
    model: function (params) {
      console.log("code-editor, model", params, parent.location.href);
      //Dont try and refresh if embedded in a course
      const isEmbeddedInCourse = parent.location.href.includes("course/") && params.embed == "true";
      if (!isEmbeddedInCourse) {
        this.get("cors").enterCode(params.document_id, params.embed, params.showCode, params.colab);
      }
      return this.get('store').findRecord('document', params.document_id);
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      if (model) {
        controller.send('refresh');
      }
    },
    deactivate: function () {
      this.get('cs').log("leaving code-editor");
      this._super();
      this.get('controller').send('cleanUp');
    },
    actions: {
      error(error, transition) {
        if (error.errors[0].status === '404') {
          this.replaceWith('application');
        } else {
          return true;
        }
      }
    }
  });
});