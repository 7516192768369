define('ember-share-db/components/project-generator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    documentService: Ember.inject.service('documents'),
    stubsService: Ember.inject.service('project-stubs'),
    sessionAccount: Ember.inject.service('session-account'),
    namesService: Ember.inject.service('name-generator'),
    session: Ember.inject.service('session'),
    didInsertElement() {
      this._super(...arguments);
      const input = this.get('stubsService').inputs();
      const model = this.get('stubsService').models();
      const output = this.get('stubsService').outputs();
      document.getElementById("project-generator-submit-btn").disabled = true;
      let inputLabels = [];
      Object.keys(input).forEach(i => {
        inputLabels.push(input[i].label);
      });
      this.set("inputLabels", inputLabels);
      let modelLabels = [];
      Object.keys(model).forEach(i => {
        modelLabels.push(model[i].label);
      });
      this.set("modelLabels", modelLabels);
      let outputLabels = [];
      Object.keys(output).forEach(i => {
        outputLabels.push(output[i].label);
      });
      this.set("outputLabels", outputLabels);
    },
    makeDoc() {
      const input = this.get("selectedInput");
      const model = this.get("selectedModel");
      const output = this.get("selectedOutput");
      const source = this.get("stubsService").combine(input, model, output);
      const data = {
        source: source,
        isPrivate: false,
        name: this.get("namesService").getName(),
        assets: [],
        assetQuota: 0,
        tags: [input, model, output]
      };
      this.get("documentService").makeNewDoc(data).then(doc => {
        console.log("made new doc", doc.id);
        setTimeout(() => {
          this.onCreate({ docid: doc.id });
        }, 10);
      });
    },
    canBuildProject() {
      const hasInput = this.get("selectedInput") !== undefined;
      const hasModel = this.get("selectedModel") !== undefined;
      const hasOutput = this.get("selectedOutput") !== undefined;
      const currentUser = this.get('sessionAccount').currentUserId;
      const isSignedIn = !Ember.isEmpty(currentUser);
      return hasInput && hasModel && hasOutput && isSignedIn;
    },
    actions: {
      onInput(index) {
        let input = Object.keys(this.get('stubsService').inputs())[index];
        console.log(input);
        this.set("selectedInput", input);
        this.set("selectedInputDescription", this.get('stubsService').inputs()[input].description);
        this.set("selectedInputLabel", this.get('stubsService').inputs()[input].label);
        document.getElementById("project-generator-submit-btn").disabled = !this.canBuildProject();
      },
      onModel(index) {
        let model = Object.keys(this.get('stubsService').models())[index];
        console.log(model);
        this.set("selectedModel", model);
        this.set("selectedModelDescription", this.get('stubsService').models()[model].description);
        this.set("selectedModelLabel", this.get('stubsService').models()[model].label);
        document.getElementById("project-generator-submit-btn").disabled = !this.canBuildProject();
      },
      onOutput(index) {
        let output = Object.keys(this.get('stubsService').outputs())[index];
        console.log(output);
        this.set("selectedOutput", output);
        this.set("selectedOutputDescription", this.get('stubsService').outputs()[output].description);
        this.set("selectedOutputLabel", this.get('stubsService').outputs()[output].label);
        document.getElementById("project-generator-submit-btn").disabled = !this.canBuildProject();
      },
      submit() {
        this.makeDoc();
      }
    }
  });
});