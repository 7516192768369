define('ember-share-db/services/uuid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    guid: function () {
      return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
    },
    s4: function () {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
  });
});