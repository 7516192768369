define('ember-share-db/routes/api', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    endpoint: '',
    model: function (params) {
      this.set('endpoint', params.endpoint);
      return params.endpoint;
    },
    redirect: function () {
      window.location = _environment.default.serverHost + "/" + this.get('endpoint');
    }
  });
});