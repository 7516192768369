define('ember-share-db/models/asset', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { attr } = _emberData.default;

  exports.default = _emberData.default.Model.extend({
    fileType: attr('string'),
    fileId: attr('string'),
    size: attr('string'),
    b64data: attr('string'),
    name: attr('string')
  });
});