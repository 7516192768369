define('ember-share-db/routes/examples', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    examples: Ember.inject.service(),
    cs: Ember.inject.service('console'),
    cors: Ember.inject.service("cors"),
    router: Ember.inject.service(),
    model(params) {
      const examples = this.get('examples').examples;
      this.get('cs').log(examples, params);
      for (let i = 0; i < examples.length; i++) {
        let group = examples[i];
        for (let j = 0; j < group.examples.length; j++) {
          let example = group.examples[j];
          if (example.id == params.topic) {
            //redirect from old urls (examples) to new course pages
            this.router.replaceWith("course.course-unit", "examples", example.id);
            return example;
          }
        }
      }
      this.router.transitionTo("course", "examples");
      return examples;
    }
  });
});