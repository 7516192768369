define('ember-share-db/components/modal-preview-body', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _arguments = arguments;
  exports.default = Ember.Component.extend({
    asset: null,
    didReceiveAttrs: () => {

      undefined._super(..._arguments);

      console.log("asset", undefined.get("asset"));
    }
  });
});