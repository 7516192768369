define('ember-share-db/components/supervised-ml-guide', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    url: _environment.default.localOrigin + "/images/",
    guideUrl: _environment.default.localOrigin + "/guides/",
    exampleUrl: _environment.default.localOrigin + "/examples/"
  });
});