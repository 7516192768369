define('ember-share-db/components/download-button', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'jszip', 'ember-share-db/config/environment'], function (exports, _fileSaver, _jszip, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {
    doc: null,
    classNames: ['inline-view'],
    assetService: Ember.inject.service('assets'),
    store: Ember.inject.service('store'),
    documentService: Ember.inject.service('documents'),
    cs: Ember.inject.service('console'),
    fetchLib: async function (url) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        this.get('cs').log("fetching lib from " + url);
        xhr.onload = () => {
          this.get('cs').log(xhr.readyState, xhr.status == 200);
          if (xhr.readyState == 4 && xhr.status == 200) {
            this.get('cs').log("fetched lib:" + url);
            resolve(xhr.responseText);
          }
        };
        xhr.onerror = () => {
          this.get('cs').log("error :" + url);
          reject("error");
        };
        xhr.overrideMimeType("text/plain; charset=x-user-defined");
        xhr.open("GET", url, true);
        xhr.send(null);
      });
    },
    fetchLibs(libs, urls, libFolder) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const getAllASync = async () => {
          for (let i = 0; i < libs.length; i++) {
            let url = urls[i];
            let lib = libs[i];
            if (url.includes("maximilian")) {
              let maxiLibs = ["enable-threads.js", "index.mjs", "maxi-processor.js", "ringbuf.js"];
              let maxiUrls = maxiLibs.map(x => "https://mimicproject.com/libs/" + x);
              await this.fetchLibs(maxiLibs, maxiUrls, libFolder);
            } else if (url.includes("maxiInstruments")) {
              let version = lib.split(".");
              version.shift();
              version = version.join(".");
              let maxiLibs = ["enable-threads.js", "ringbuf.js", "maxiSynthProcessor." + version];
              let maxiUrls = maxiLibs.map(x => "https://mimicproject.com/libs/" + x);
              await this.fetchLibs(maxiLibs, maxiUrls, libFolder);
            }
            await this.fetchLib(url).then(text => {
              this.get("cs").log(lib);
              libFolder.file(lib, text);
            }).catch(err => {
              this.get('cs').log("ERROR IN FETCHING library");
              reject(err);
              return;
            });
          }
          this.get("cs").log("done fetching");
          resolve();
        };
        getAllASync();
      });
    },
    actions: {
      async download() {
        const data = this.get('doc');
        this.get('cs').log(data);
        let zip = new _jszip.default();
        this.get('documentService').getCombinedSource(data.id).then(source => {

          //Define end function (called after all libraries worked out)
          const completeZip = () => {
            zip.file("index.html", source, { type: 'string' });
            zip.file("readme.md", `

            Mac OSX
            Open Terminal
            Run python libs/server.py
            Open Browser, go to localhost:4201

            Windows
            Open Command Prompt
            Run python libs/server.py
            Open Browser, go to localhost:4201

            `, { type: 'string' });
            for (let asset of data.assets) {
              const storeAsset = this.get('store').peekRecord('asset', asset.fileId);
              if (storeAsset) {
                zip.file(asset.name, storeAsset.b64data, { base64: true });
              }
            }
            this.get("cs").log("generateAsync");
            zip.generateAsync({ type: "blob" }).then(blob => {
              this.get("cs").log("saveFileAs");
              this.saveFileAs(data.name + "-MIMIC.zip", blob, 'application/zip');
            }).catch(err => this.get('cs').log(err));
          };

          //find any external scripts
          let pattern = new RegExp('<script .*src.*\\.(js|css)\\".?>\\n?<\\/script>', 'g');
          let matches = source.match(pattern);
          if (!Ember.isEmpty(matches)) {
            //Get urls and library (.js) names
            let urls = matches.map(x => x.split("\"")[1]);
            //Also get the server (to serve files)
            urls.push("https://mimicproject.com/libs/server.py");
            let libs = urls.map(x => x.split("/").pop());
            let libFolder = zip.folder("libs");
            console.log(urls);
            console.log(libs);

            //Replace url with ../
            for (let i = 0; i < libs.length; i++) {
              let url = urls[i];
              let lib = libs[i];
              source = source.replace(new RegExp(url, "gm"), "\.\./libs/" + lib);
            }
            //Fetch libraries (async) and add into libs/ in .zip
            this.fetchLibs(libs, urls, libFolder).then(completeZip);
          } else {
            completeZip();
          }
        }).catch(err => this.get('cs').log(err));
      }
    }
  });
});