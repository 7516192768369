define("ember-share-db/components/embedded-project", ["exports", "ember-share-db/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    docId: "",
    cs: Ember.inject.service("console"),
    url: _environment.default.localOrigin,
    height: "490px",
    loaded: false,
    manualLoad: false,
    logoURL: _environment.default.localOrigin + "/images/logo-animation-cropped.gif",
    projectLink: true,
    srcURL: "about:none",
    buttonTop: Ember.computed("height", function () {
      let height = this.get("height");
      height = height.substring(0, height.length - 2);
      height = parseInt(height);
      return height / 2 + "px";
    }),
    didInsertElement() {
      this._super(...arguments);
      if (!this.get("manualLoad")) {
        this.observe();
      }
    },
    observe: function () {
      var options = {
        root: document.querySelector("#scrollArea")
      };

      var observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (!this.get("loaded")) {
            //If onscreen
            if (entry.intersectionRatio > 0) {
              this.set("doLoad", true);
              setTimeout(() => {
                //Wait until it has been onscreen for 1 sec before loading
                if (this.get("doLoad")) {
                  let src = this.get("url") + "/code/" + this.get("docId") + "?embed=true&showCode=true";
                  this.get("cs").log("onscreen", src);
                  this.set("srcURL", src);
                  this.set("loaded", true);
                }
              }, 1000);
            }
          }
          if (entry.intersectionRatio <= 0) {
            //if offscreen before timeout occurs, cancel loading
            this.set("doLoad", false);
            this.get("cs").log("offscreen", this.get("docId"));
          }
        });
      }, options);

      observer.observe(document.getElementById(this.elementId));
    },
    actions: {
      loadProject() {
        console.log("Load project", this.get("docId"));
        this.set("manualLoad", false);
        let src = this.get("url") + "/code/" + this.get("docId") + "?embed=true&showCode=true";
        this.set("srcURL", src);
        this.set("loaded", true);
      }
    }
  });
});