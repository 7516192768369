define('ember-share-db/components/tokenfield-input', ['exports', 'ember-share-db/templates/components/tokenfield-input'], function (exports, _tokenfieldInput) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.KEYCODE = undefined;
    const KEYCODE = exports.KEYCODE = {
        ENTER: 13,
        DELETE: 46,
        S: 83,
        s: 115,
        LEFT_ARROW: 37,
        RIGHT_ARROW: 39,
        BACKSPACE: 8,
        TAB: 9,
        COMMA: 188
    };

    exports.default = Ember.Component.extend({
        // Component properties
        layout: _tokenfieldInput.default,
        classNames: ['uncharted-tokenfield-input'],
        classNameBindings: ['isFocused:uncharted-focus'],
        attributeBindings: ['tabindex'],
        tabindex: 0,

        // Properties
        tokens: null,
        placeholder: null,
        addTokenOnBlur: true,
        allowDuplicates: false,
        editable: false,
        showInput: false,
        tokenComponent: 'base-token',

        tokenfieldId: Ember.computed('elementId', function () {
            return `${this.elementId}-tokenfield`;
        }),
        addButtonId: Ember.computed('elementId', function () {
            return `${this.elementId}-add-btn`;
        }),
        labelId: Ember.computed('elementId', function () {
            return `${this.elementId}-token-label`;
        }),

        // State
        inputValue: null,
        isFocused: null,
        hasTokens: Ember.computed.notEmpty('tokens'),
        selectedTokenIndex: null,
        showDuplicateMessage: false,

        // Lifecycle
        init() {
            this._super(...arguments);
            if (Ember.isNone(this.get('tokens'))) {
                this.set('tokens', Ember.A());
            }
        },

        didUpdateAttrs() {
            this._super(...arguments);
            const addBtn = document.getElementById(this.get("addButtonId"));
            if (!Ember.isEmpty(addBtn)) {
                if (this.get('editable')) {
                    addBtn.style.display = 'block';
                } else {
                    addBtn.style.display = 'none';
                }
            }
        },

        didInsertElement() {
            this._super(...arguments);

            // const textInput = document.getElementsByClassName("uncharted-token-input");
            // console.log(textInput);
            const textInput = $(".uncharted-token-input");
            this._textInputElement = textInput;
            textInput.on('keydown', this._keydownHandler.bind(this)).on('focus', this._focusHandler.bind(this)).on('blur', this._inputWasBlurred.bind(this));

            const me = document.getElementsByClassName("uncharted-form-control")[0];
            console.log(me);
            me.onkeydown = this._tokenNavigationHandler.bind(this);
            me.onfocus = this._focusHandler.bind(this);
            me.onblur = this._componentWasBlurred.bind(this);
        },

        willDestroyElement() {
            this._super(...arguments);

            this._textInputElement.off('keydown', this._keydownHandler.bind(this)).off('focus', this._focusHandler.bind(this)).off('blur', this._inputWasBlurred.bind(this));

            const me = document.getElementsByClassName("uncharted-form-control")[0];
            console.log(me);
            me.onkeydown = this._tokenNavigationHandler.bind(this);
            me.onfocus = this._focusHandler.bind(this);
            me.onblur = this._componentWasBlurred.bind(this);
        },

        toggleInput() {
            this.toggleProperty('showInput');
            this.set('showDuplicateMessage', false);
            this.inputToggled();
        },

        // Actions
        actions: {

            addPressed() {
                this.toggleInput();
            },

            editToken(token) {
                this._editToken(token);
            },

            removeToken(token) {
                this._removeToken(token);
                this._focusTextInput();
            },

            createToken(token) {
                this._addToken(token);
                this._focusTextInput();
            },

            selectToken(token, index) {
                if (this.get('editable')) {
                    return;
                }
                console.log("HERE", token, index);
                this.searchTag(token);
                this.set('selectedTokenIndex', index);
            }
        },

        _onDisabledChanged: Ember.observer('disabled', function () {
            if (!this.get('editable')) {
                this._blurComponent();
            }
        }),

        _onInputValueChanged: Ember.observer('inputValue', function () {
            if (!this.get('editable')) {
                return;
            }
            const value = this.get('inputValue');
            if (value.indexOf(',') > -1) {
                const values = value.split(',');
                values.forEach(this._addToken.bind(this));
                this.set('inputValue', '');
            }
        }),

        // Event handlers
        _keydownHandler(e) {
            if (!this.get('editable')) {
                return;
            }
            const wasEnterKey = e.which === KEYCODE.ENTER;
            const wasTabKey = e.which === KEYCODE.TAB;
            const hasValue = !Ember.isEmpty(this.get('inputValue'));
            const shouldCreateToken = wasEnterKey || wasTabKey && hasValue;

            if (this.get('showDuplicateMessage')) {
                this.set('showDuplicateMessage', false);
            }

            if (shouldCreateToken) {
                this._addToken(this.get('inputValue'));
                e.preventDefault();
                e.stopPropagation();
            }
        },

        _tokenNavigationHandler(e) {
            if (!this.get('editable')) {
                return;
            }
            // Highlight text hit backspace wtf?!?!
            const cursorIndex = e.target.selectionStart;
            const cursorIsAtStart = cursorIndex === 0;
            const hasSelectedToken = !Ember.isNone(this.get('selectedTokenIndex'));
            switch (e.which) {
                case KEYCODE.BACKSPACE:
                    if (hasSelectedToken) {
                        const prevTokenIndex = this.get('selectedTokenIndex') - 1;
                        this._removeToken(this.get('tokens').objectAt(this.get('selectedTokenIndex')));
                        if (prevTokenIndex > -1) {
                            this._setSelectedTokenIndex(prevTokenIndex);
                            e.preventDefault();
                        } else {
                            this._focusTextInput();
                        }
                    } else if (Ember.isEmpty(this.get('inputValue')) && cursorIsAtStart) {
                        this._setSelectedTokenIndex(this.get('tokens.length') - 1);
                        e.preventDefault();
                    }
                    break;
                case KEYCODE.ENTER:

                    if (hasSelectedToken) {
                        const tokenValue = this.get('tokens').objectAt(this.get('selectedTokenIndex'));
                        this._editToken(tokenValue);
                    } else if (!Ember.isEmpty(this.get('inputValue'))) {
                        this._addToken(this.get('inputValue'));
                    }
                    break;
                case KEYCODE.LEFT_ARROW:
                    if (hasSelectedToken) {
                        const prevTokenIndex = this.get('selectedTokenIndex') - 1;
                        if (prevTokenIndex > -1) {
                            this._setSelectedTokenIndex(prevTokenIndex);
                        }
                    } else if (Ember.isEmpty(this.get('inputValue'))) {
                        this._setSelectedTokenIndex(this.get('tokens.length') - 1);
                    }
                    break;
                case KEYCODE.RIGHT_ARROW:
                    {
                        const selectedTokenIndex = this.get('selectedTokenIndex');
                        if (Ember.isNone(selectedTokenIndex)) {
                            break;
                        }

                        if (selectedTokenIndex >= this.get('tokens.length') - 1) {
                            // We were at the last token so lets focus the text input
                            this._setSelectedTokenIndex(null);
                            this._focusTextInput();
                        } else {
                            this._setSelectedTokenIndex(selectedTokenIndex + 1);
                        }
                        break;
                    }
                case KEYCODE.TAB:
                    if (hasSelectedToken) {
                        this._blurComponent();
                    } else if (!Ember.isEmpty(this.get('inputValue'))) {
                        this._addToken(this.get('inputValue'));
                    }
                    break;
            }
        },

        _focusHandler(e) {
            if (!this.get('editable')) {
                return;
            }
            this.set('isFocused', true);
            if (e.target === this.element) {
                // Div focus event
                if (Ember.isNone(this.get('selectedTokenIndex'))) {
                    this._focusTextInput();
                }
            } else {
                // Input focus event
                this.set('selectedTokenIndex', null);
            }
        },

        _componentWasBlurred() {
            if (!this.get('editable')) {
                return;
            }
            this.set('isFocused', false);
            this.set('selectedTokenIndex', null);
        },

        _inputWasBlurred() {
            if (Ember.isNone(this.get('selectedTokenIndex'))) {
                this._blurComponent();
            }
        },

        // Internal methods
        _focusComponent() {
            const me = document.getElementsByClassName("uncharted-form-control");
            console.log(me);
            me.focus();
        },

        _focusTextInput() {
            if (!this.get('editable')) {
                return;
            }
            this._textInputElement.focus();
        },

        _blurComponent() {
            if (!this.get('editable')) {
                return;
            }
            if (this.get('addTokenOnBlur') && !Ember.isEmpty(this.get('inputValue'))) {
                this._addToken(this.get('inputValue'));
            }
            this.set('isFocused', false);
            this.set('selectedTokenIndex', null);
        },

        _setSelectedTokenIndex(index) {
            if (!this.get('editable')) {
                return;
            }
            this.set('selectedTokenIndex', index);
            this._textInputElement.blur();
            if (!Ember.isNone(index)) {
                this._focusComponent();
            }
        },

        _removeToken(value) {
            if (!this.get('editable')) {
                return;
            }
            this.get('tokens').removeObject(value);
            this.set('selectedTokenIndex', null);
            this.get('tokensChanged')(this.get('tokens'));
        },

        _addToken(value) {
            if (!this.get('editable')) {
                return;
            }
            if (!Ember.isNone(value)) {
                value = value.trim();
                const isDuplicate = this.get('tokens').map(token => token.toLowerCase()).includes(value.toLowerCase());
                const allowDuplicates = this.get('allowDuplicates');
                const hasValue = !Ember.isEmpty(value);
                const willAdd = hasValue && (allowDuplicates || !isDuplicate);

                if (willAdd) {
                    this.get('tokens').pushObject(value);
                    this.set('inputValue', '');
                    this.get('tokensChanged')(this.get('tokens'));
                    this.set('showDuplicateMessage', false);
                } else if (!allowDuplicates && isDuplicate) {
                    this.set('showDuplicateMessage', true);
                }
            }
        },

        _editToken(value) {
            if (!this.get('editable')) {
                return;
            }
            this._removeToken(value);
            if (!Ember.isNone(this.get('inputValue'))) {
                this._addToken(this.get('inputValue'));
            }
            this.set('inputValue', value);
            Ember.run.schedule('afterRender', this, function () {
                this._textInputElement.focus();
                this._textInputElement.select();
            });
            this.set('showInput', true);
            this.set('showDuplicateMessage', false);
        }
    });
});