define('ember-share-db/services/autocomplete', ['exports', 'codemirror'], function (exports, _codemirror) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cs: Ember.inject.service('console'),
    tabs: children => {
      return children.map(child => {
        return child.data.name;
      });
    },
    assets: assets => {
      return assets.map(asset => {
        return asset.name;
      });
    },
    ruleSets(docType) {
      //this.get('cs').log("getting rule set for" ,docType);
      let ruleSets = {
        "tagname-lowercase": true,
        "attr-lowercase": true,
        "attr-value-double-quotes": false,
        "tag-pair": true,
        "spec-char-escape": true,
        "id-unique": true,
        "src-not-empty": true,
        "attr-no-duplication": true,
        "csslint": {
          "display-property-grouping": true,
          "known-properties": true
        },
        "jshint": { "esversion": 6, "asi": true }
      };
      if (docType == "custom-javascript") {
        ruleSets = {
          "tagname-lowercase": false,
          "attr-lowercase": false,
          "attr-value-double-quotes": false,
          "tag-pair": false,
          "spec-char-escape": false,
          "id-unique": false,
          "src-not-empty": false,
          "attr-no-duplication": false,
          "csslint": {
            "display-property-grouping": false,
            "known-properties": false
          },
          "jshint": { "esversion": 6, "asi": true }
        };
      }
      return ruleSets;
    }
  });
});