define('ember-share-db/components/filter-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filter: null,
    willDestroyElement() {
      this._super(...arguments);
    },
    didUpdateAttrs() {
      this._super(...arguments);
    },
    didReceiveAttrs() {
      this._super(...arguments);
    },
    actions: {
      onFilter() {
        this.get('onFilter')(this.get('filter'));
      }
    }
  });
});