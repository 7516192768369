define('ember-share-db/serializers/document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute(attr) {
      if (attr == 'document-Id') return 'documentId';else {
        return attr;
      }
    }
  });
});