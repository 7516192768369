define("ember-share-db/routes/courses", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    //   courses: inject(),
    cs: Ember.inject.service("console"),
    store: Ember.inject.service(),
    async model(params) {
      // this.get("store")
      //   .findAll("course")
      //   .then((results) => {
      //     console.log(results);
      //   });
      // console.log(this.get("store").findAll("course"));
      //     const courses = this.get("courses").guides;
      //     this.get("cs").log(courses, params);
      //     for (let i = 0; i < courses.length; i++) {
      //       let group = courses[i];
      //       for (let j = 0; j < group.guides.length; j++) {
      //         let guide = group.guides[j];
      //         this.get("cs").log(guide.id == params.topic, guide.id, params.topic);
      //         if (guide.id == params.topic) {
      //           this.get("cs").log("EARLY RETURNIGN");
      //           return guide;
      //         }
      //       }
      //     }
      return this.get("store").findAll("course");
    }
  });
});