define('ember-share-db/adapters/account', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-share-db/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { JSONAPIAdapter } = _emberData.default;

  exports.default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.serverHost,
    session: Ember.inject.service('session'),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      const headers = {};
      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
      }

      return headers;
    })
    // authorize(xhr) {
    //   let { access_token } = this.get('session.data.authenticated');
    //   xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    // },
  });
});