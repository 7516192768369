define('ember-share-db/services/cors', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    checkRefreshFlags() {
      if (sessionStorage.getItem('doRefreshGuide') === null) {
        sessionStorage.setItem('doRefreshGuide', "true");
      }
      if (sessionStorage.getItem('lastCodeReset') === null) {
        sessionStorage.setItem('lastCodeReset', 0);
      }
    },
    hasSAB() {
      if (typeof SharedArrayBuffer !== 'undefined') {
        // SharedArrayBuffer is defined
        console.log('SharedArrayBuffer is supported.');
        return true;
      } else {
        // SharedArrayBuffer is not defined
        console.log('SharedArrayBuffer is not supported.');
        return false;
      }
    },
    doRefreshCode() {
      return Date.now() - sessionStorage.getItem('lastCodeReset') > 2000 && !this.hasSAB();
    },
    didResetCode() {
      sessionStorage.setItem('lastCodeReset', Date.now());
      sessionStorage.setItem('doRefreshGuide', "true");
    },
    enterCourse(courseUnit, courseId, hasCode) {
      this.checkRefreshFlags();
      if (!hasCode) {
        if (sessionStorage.getItem('doRefreshGuide') == "true") {
          sessionStorage.setItem('doRefreshGuide', "false");
          window.location.href = _environment.default.localOrigin + "/course/" + courseUnit.get("id") + "/" + courseId.get("id");
        }
      } else {
        //if has code we need to go refresh WITH headers
        if (this.doRefreshCode()) {
          this.didResetCode();
          window.location.href = _environment.default.localOrigin + "/addcors?course=" + courseUnit.get("id") + "&courseid=" + courseId.get("id");
        }
      }
    },
    enterExample(example) {
      if (this.doRefreshCode()) {
        this.didResetCode();
        window.location.href = _environment.default.localOrigin + "/examples/" + example;
      }
    },
    enterCode(docId, embed, showCode, colab) {
      this.checkRefreshFlags();
      console.log("entering code", embed);
      if (this.doRefreshCode()) {
        console.log("refreshing code");
        this.didResetCode();
        var URL = _environment.default.localOrigin + "/code/" + docId;
        if (embed !== undefined) {
          URL += "?embed=" + embed;
        }
        if (showCode !== undefined) {
          URL += "&showCode=" + showCode;
        }
        if (colab !== undefined) {
          URL += "&colab=" + colab;
        }
        window.location.href = URL;
      }
    }
  });
});