define("ember-share-db/helpers/sanityImageUrlBuilder", ["exports", "@sanity/image-url", "ember-share-db/sanityClient"], function (exports, _imageUrl, _sanityClient) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  console.log("sanity client", _sanityClient.client);

  // Get a pre-configured url-builder from your sanity client
  const builder = (0, _imageUrl.default)(_sanityClient.client);

  // Then we like to make a simple function like this that gives the
  // builder an image and returns the builder for you to specify additional
  // parameters:
  function sanityImageUrlBuilder([source, width, height]) {
    if (!source) return "";

    return builder.image(source).width(width).height(height);
  }

  exports.default = Ember.Helper.helper(sanityImageUrlBuilder);
});