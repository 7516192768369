define('ember-share-db/services/-ensure-registered', ['exports', '@embroider/util/services/ensure-registered'], function (exports, _ensureRegistered) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ensureRegistered.default;
    }
  });
});