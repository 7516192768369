define('ember-share-db/services/modal-dialog', ['exports', 'ember-share-db/config/environment', 'ember-modal-dialog/utils/config-utils'], function (exports, _environment, _configUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function computedFromConfig(prop) {
    return Ember.computed(function () {
      return _environment.default['ember-modal-dialog'] && _environment.default['ember-modal-dialog'][prop];
    });
  }

  exports.default = Ember.Service.extend({
    hasEmberTether: computedFromConfig('hasEmberTether'),
    hasLiquidWormhole: computedFromConfig('hasLiquidWormhole'),
    hasLiquidTether: computedFromConfig('hasLiquidTether'),
    destinationElementId: Ember.computed(function () {
      return (0, _configUtils.getDestinationElementIdFromConfig)(_environment.default);
    })
  });
});