define("ember-share-db/routes/course/course-unit", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    //   courses: inject(),
    cs: Ember.inject.service("console"),
    store: Ember.inject.service(),
    cors: Ember.inject.service("cors"),
    setupController(controller, model, transition) {
      this._super(...arguments);
      const { course_unit_id } = transition.to.params || {};
      controller.set("courseUnitId", course_unit_id);

      // TODO: this probably belongs elsewhere
      // // scrolls to top

      if (window.scrollY > window.innerHeight) {
        window.scrollTo(0, 0);
      }
    },
    async model(params) {
      console.log("fetch course unit params", params);
      const { course_unit_id } = params;
      return this.get("store").findRecord("course-unit", course_unit_id).then(courseUnit => {
        const currCourse = this.modelFor("course");

        const currCourseUnitIndex = currCourse.units.findIndex(unit => {
          return unit.id == courseUnit.id;
        });

        let prevUnit, nextUnit;
        if (currCourseUnitIndex > 0) {
          prevUnit = currCourse.units[currCourseUnitIndex - 1];
        }
        if (currCourseUnitIndex < currCourse.units.length - 1) {
          nextUnit = currCourse.units[currCourseUnitIndex + 1];
        }

        console.log("prevUnit", prevUnit, "nextUnit", nextUnit);
        console.log("courseUnit", courseUnit.contents);
        let hasCode,
            hasVideo = false;
        for (var c of courseUnit.contents) {
          if (c._type == "mimicDoc") {
            hasCode = true;
          }
        }
        for (var c of courseUnit.contents) {
          if (c._type == "videoEmbed") {
            hasVideo = true;
            //If video, dont add in cors headers like we would normally for code (breaks video)
            hasCode = false;
          }
        }
        this.get("cors").enterCourse(currCourse, courseUnit, hasCode);

        return {
          course: currCourse,
          courseUnit,
          prevUnit,
          nextUnit
        };
      });
    },
    actions: {
      didTransition() {
        // preload next course unit
        if (this.currentModel.nextUnit && this.currentModel.nextUnit.id) {
          this.get("store").findRecord("course-unit", this.currentModel.nextUnit.id);
        }
        return true; // Bubble the didTransition event
      }
    }
  });
});