define("ember-share-db/components/portable-text-mimic-block", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isRevealed: false,
    actions: {
      onClickReveal() {
        console.log("onClickReveal");
        this.set("isRevealed", true);
      }
    }
  });
});