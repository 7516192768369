define("ember-share-db/controllers/course/course-unit", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      onFooterLinkClick() {
        window.scrollTo(0, 0);
      }
    }
  });
});