define('ember-share-db/components/people-tile', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isSelected: false,
    store: Ember.inject.service('store'),
    index: 0,
    svgClass: "shape-svg people-shape-svg",
    colourId: Ember.computed('index', function () {
      return "tile" + this.get('index') % 5;
    }),
    actions: {
      onClick() {
        window.open(this.get("person.personalURL"));
      },
      onover() {
        this.set('isSelected', true);
      },
      onout() {
        this.set('isSelected', false);
      }
    }
  });
});