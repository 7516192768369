define("ember-share-db/sanityClient", ["exports", "@sanity/client"], function (exports, _client) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.client = undefined;

  // Import using ESM URL imports in environments that supports it:
  // import {createClient} from 'https://esm.sh/@sanity/client'

  const client = exports.client = (0, _client.createClient)({
    projectId: "tl2rw0ku",
    dataset: "production",
    useCdn: false, // set to `true` to fetch from edge cache
    apiVersion: "2022-01-12" // use current date (YYYY-MM-DD) to target the latest API version
    // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
  });
});