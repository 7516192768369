define('ember-share-db/components/mobile-code-label', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        router: Ember.inject.service(),
        didInsertElement() {
            this._super(...arguments);
            const embed = this.get("router").currentRoute.queryParams["embed"];
            if (embed !== undefined) {
                if (embed == "true") {
                    const nav = document.getElementById("mimic-navbar");
                    nav.style.display = "none";
                    nav.style["padding-top"] = "2px";
                    const logo = document.getElementById("main-logo");
                    logo.style.display = "none";
                    const log = document.getElementById("login-container");
                    log.style.top = "20px";
                    const footer = document.getElementById("mimic-footer");
                    footer.style.display = "none";
                    const container = document.getElementById("main-site-container");
                    container.style["padding-left"] = "0%";
                    container.style["padding-right"] = "0%";
                }
            }
        }
    });
});