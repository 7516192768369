define('ember-share-db/components/gallery-list-item', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    document: null,
    documentService: Ember.inject.service('documents'),
    cs: Ember.inject.service('console'),
    iframeTitle: "title",
    hasWritten: false,
    smallGrid: false,
    url: _environment.default.localOrigin,
    iframeId: "gallery-output-iframe",
    containerId: "gallery-output-container",
    didReceiveAttrs() {
      this._super(...arguments);
      this.setup();
    },
    documentName: Ember.computed('document', function () {
      const doc = this.get("document");
      if (!Ember.isEmpty(doc._internalModel)) {
        return this.get('document')._internalModel.__recordData.__data.name;
      } else {
        return this.get('document').__recordData.__data.name;
      }
    }),
    documentOwner: Ember.computed('document', function () {
      const doc = this.get("document");
      if (!Ember.isEmpty(doc._internalModel)) {
        return this.get('document')._internalModel.__recordData.__data.owner;
      } else {
        return this.get('document').__recordData.__data.owner;
      }
    }),

    setup() {
      if (!this.get("hasWritten")) {
        this.set("hasWritten", true);
        console.log(this.get("document"));
        const docId = this.get("document").id;
        this.set("iframeId", "gallery-output-iframe-" + docId);
        this.set("containerId", "gallery-output-container-" + docId);
        let source = this.get("document").source;
        this.get('documentService').getCombinedSource(docId, true, source).then(combined => {
          const srcRegex = /<\s*script\s+.*\s*src\s*=\s*["']\s*\.\.\/libs\//gm;
          const srcUrl = "<script crossorigin src = \"" + _environment.default.localOrigin + "/libs/";
          const cssRegex = /<\s*link\s+.*rel\s*=\s*["']stylesheet["']\s+href\s*=\s*["']\s*\.\.\/libs\//gm;
          const cssUrl = "<link rel = \"stylesheet\" href = \"" + _environment.default.localOrigin + "/libs/";
          let swapRelativePaths = combined.replace(srcRegex, srcUrl);
          swapRelativePaths = swapRelativePaths.replace(cssRegex, cssUrl);
          console.log(combined, swapRelativePaths);
          this.writeIframeContent(swapRelativePaths);
        });
      }
    },
    writeIframeContent: function (src) {
      const viewer = document.getElementById(this.get("iframeId"));
      this.get("cs").log("writeIframeContent", viewer);
      if (!Ember.isEmpty(viewer)) {
        console.log("has viewer");
        const cd = viewer.contentDocument;
        if (!Ember.isEmpty(cd)) {
          this.get("cs").log("has content doc");
          const parent = document.getElementById(this.get("containerId"));
          const newIframe = document.createElement('iframe');
          newIframe.onload = function () {
            let iframeDocument = newIframe.contentDocument || newIframe.contentWindow.document;
            let styleElement = iframeDocument.createElement('style');
            styleElement.textContent = 'body { overflow: hidden; }';
            iframeDocument.head.appendChild(styleElement);
          };
          newIframe.setAttribute("id", this.get("iframeId"));
          newIframe.setAttribute("title", "output-iframe");
          newIframe.setAttribute("name", this.get("iframeTitle"));
          newIframe.setAttribute("class", "gallery-output-iframe");
          parent.appendChild(newIframe);
          const newCd = newIframe.contentDocument;

          newCd.open();
          this.get("cs").log(src);
          try {
            newCd.write(src);
          } catch (err) {
            this.get('cs').log("error running code", err);
          }
          newCd.close();

          const delay = src == "" ? 0 : 10;
          viewer.setAttribute("class", "output-iframe-gone");
          setTimeout(() => {
            cd.open();
            try {
              cd.write("");
            } catch (err) {
              this.get('cs').log("error running code");
            }
            cd.close();
            viewer.parentNode.removeChild(viewer);
          }, delay);
        }
      }
    },
    actions: {
      open() {
        this.get('onOpen')(this.get("document").id);
      }
    }
  });
});