define("ember-share-db/helpers/processContents", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function processContents([contents]) {
    if (!contents || !contents.length) return [];
    console.log("processContents", contents);
    const newContents = contents.map(content => Object.assign({}, content));

    // TODO trim whitespace from beginning and end

    // markup links to be rendered correctly
    newContents.forEach(contentBlock => {
      if (contentBlock.markDefs) {
        contentBlock.markDefs.forEach(markDef => {
          if (markDef._type == "link") {
            console.log("FOUND LINK", markDef);
            const linkChild = contentBlock.children.find(child => {
              console.log("child._key", child, markDef._key);
              return child.marks.includes(markDef._key);
            });
            if (linkChild) {
              linkChild.isLink = true;
              linkChild.href = markDef.href;
              console.log("FOUND LINK CHILD", linkChild);
            }
          }
        });
      }
    });

    // Add mark for first paragraph in a group of paragraphs to help with proper styling
    let isInNormalText;
    newContents.forEach(contentBlock => {
      if (!isInNormalText && contentBlock._type == "block" && contentBlock.style == "normal") {
        const marks = contentBlock.marks || [];
        marks.push("first");
        contentBlock.marks = marks;

        isInNormalText = true;
      }

      if (isInNormalText && contentBlock.style != "normal") {
        isInNormalText = false;
      }
    });

    return newContents;
  }

  exports.default = Ember.Helper.helper(processContents);
});