define('ember-share-db/adapters/document', ['exports', 'ember-data', 'ember-share-db/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.serverHost,
    sessionAccount: Ember.inject.service('session-account'),
    headers: Ember.computed('sessionAccount.bearerToken', function () {
      return {
        'Authorization': 'Bearer ' + this.get('sessionAccount.bearerToken')
      };
    })
  });
});