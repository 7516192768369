define('ember-share-db/components/tab-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cs: Ember.inject.service('console'),
    didReceiveAttrs() {
      this._super(...arguments);
      const tabid = "tab" + (this.get('tabIndex') + 1) % 5;
      this.set('tabID', tabid);
    },
    actions: {
      onSelect() {
        this.get('cs').log("tab item selected", this.get('id'));
        this.get('onSelect')(this.get('id'));
      },
      onDelete() {
        this.get('onDelete')(this.get('id'));
      },
      onRename() {
        this.get('onRename')(this.get('id'));
      }
    }
  });
});