define('ember-share-db/components/file-upload', ['exports', 'ember-uploader', 'ember-share-db/config/environment'], function (exports, _emberUploader, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    sessionAccount: Ember.inject.service('session-account'),
    cs: Ember.inject.service('console'),
    url: _environment.default.serverHost + "/asset",
    uploadFile: function (file) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const uploader = _emberUploader.default.Uploader.create({
          url: this.get('url')
        });
        if (!Ember.isEmpty(file)) {
          let user = this.get('sessionAccount').currentUserName;
          let doc = this.get('sessionAccount').currentDoc;
          let data = { username: user, documentId: doc };
          this.get('cs').log(data);
          uploader.on('progress', e => {
            this.get('cs').log('progress', e);
            this.get('onProgress')(e);
          });
          uploader.on('didUpload', e => {
            this.get('cs').log('didUpload', e);
            this.get('onCompletion')(e);
            resolve();
          });
          uploader.on('didError', (jqXHR, textStatus, errorThrown) => {
            this.get('cs').log('didError', jqXHR, textStatus, errorThrown);
            this.get('onError')(errorThrown);
            reject();
          });
          uploader.upload(file, data);
        } else {
          reject();
        }
      });
    },
    filesDidChange: async function (files) {
      this.get('cs').log("files to upload", files);
      for (var i = 0; i < files.length; i++) {
        await this.uploadFile(files[i]);
      }
      this.get('onAllCompletion')();
    }
  });
});