define("ember-share-db/helpers/not", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function not([a]) {
    return !a;
  }

  exports.default = Ember.Helper.helper(not);
});