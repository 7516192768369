define('ember-share-db/routes/password-reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function (controller, model) {
      controller.isTokenValid();
    }
  });
});