define('ember-share-db/helpers/in-list', ['exports', 'ember-railio-grid/helpers/in-list'], function (exports, _inList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inList.default;
    }
  });
  Object.defineProperty(exports, 'inList', {
    enumerable: true,
    get: function () {
      return _inList.inList;
    }
  });
});