define("ember-share-db/helpers/isEqual", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isEqual([a, b]) {
    return a == b;
  }

  exports.default = Ember.Helper.helper(isEqual);
});