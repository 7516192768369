define('ember-share-db/controllers/nime2020', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    supervisedLearningURL: _environment.default.localOrigin + "/images/supervisedlearning.png",
    mimicSupervisedLearningURL: _environment.default.localOrigin + "/images/supervisedlearninglearnermimic.png",
    demoOneURL: _environment.default.localOrigin + "",
    demoTwoURL: _environment.default.localOrigin + ""
  });
});