define("ember-share-db/helpers/sortCourses", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Sort courses by published date
  function sortCourses([courses]) {
    if (!courses) return courses;

    console.log("courses", courses);

    return courses.sort((a, b) => {
      a["published-at"] - b["published-at"];
    });
    //   return !a;
    return courses;
  }

  exports.default = Ember.Helper.helper(sortCourses);
});