define("ember-share-db/helpers/getCourseAuthor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Get course author from the author property on the first unit of the course.
  // Default to CCI if author can't be determined.
  function getCourseAuthor([course]) {
    if (!course || !course.units || !course.units.length) return "CCI";

    const firstUnit = course.units[0];
    if (firstUnit.author) return firstUnit.author;

    return "CCI";
  }

  exports.default = Ember.Helper.helper(getCourseAuthor);
});