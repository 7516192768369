define('ember-share-db/routes/about', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);
      sessionStorage.setItem('doRefreshGuide', "true");
      controller.send('refresh');
    },
    model: function () {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let galleryIds = ["690f55a6-96f4-20af-ca9a-b61e3ae26369", "acfe10aa-e443-d27a-edcf-5b9f05d9d5b4", "d5d7cc76-669a-9317-5872-0635027a69f0", "a614040c-85c9-52f4-31bb-a52e22a378c3", "d0a6ab78-4d90-42c9-94b6-df149e845804", "18c54126-233b-a5a2-b1eb-22767388fee1", "95867df7-fcf3-8bee-8128-882e2126e4d4", "76b55ad6-5a5c-6015-d9eb-5c591fb9df29", "2baab49c-1a8f-fcb6-449d-b007c07cd4d8", "f142db15-619f-e9cc-5b91-8affde301802", "afffcc23-9f59-b403-8cec-78dbfdfe588b", "00368b5f-05d2-27b5-c719-5e5652840d41", "c3951d86-abe9-50f2-ce27-7bcf0c966192", "3be30824-05f4-fa7c-8eb2-0ec3d64c3bb9", "fb0a892f-e8ad-558a-b31c-ce2d486ccd75", "d6ce682d-5e58-c20b-2eaa-b5d0ebab15f2", "ab04a0e8-8b3f-af96-78bd-bcf29cdbd91a", "1a544f9b-3b12-4cec-722a-49febffb5a97", "4de2f90c-a24e-d407-7fe0-97c4011446ce", "eaecd309-9983-6a60-c362-10d466223a25"];
        let fetch = docId => {
          return new Ember.RSVP.Promise((res, rej) => {
            this.get('store').findRecord('document', docId).then(doc => res(doc)).catch(err => rej(err));
          });
        };
        let actions = galleryIds.map(fetch);
        Promise.all(actions).then(docsToShow => {
          resolve({ "docs": docsToShow });
        });
      });
    }
  });
});