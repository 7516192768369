define('ember-share-db/services/ops-player', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parser: Ember.inject.service('code-parsing'),
    sessionAccount: Ember.inject.service('session-account'),
    cs: Ember.inject.service('console'),
    ops: null,
    opsToApply: null,
    fromPlayer: null,
    prevDir: null,
    doc: null,
    reset(doc) {
      this.set('doc', doc);
      this.set('ops', null);
      this.set('fromPlayer', []);
    },
    getToSend() {
      let toSend = [];
      if (this.get("fromPlayer").length > 0) {
        toSend = JSON.parse(JSON.stringify(this.get('fromPlayer')));
        this.set("fromPlayer", []);
      }
      return toSend;
    },
    //Called every 100ms, collects ops that are before given time
    //on the the fromPlayer array
    executeUntil(time, justSource = false) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let toSend = [];
        if (!Ember.isEmpty(this.get("ops"))) {
          this.get("ops").forEach(currentOp => {
            let send = false;
            //Docs made earlier than 20/3/21 wont work with this (no dates!)
            currentOp.op.forEach(op => {
              //this.get('cs').log("executeUntil",op)
              let hasDate = false;
              if (op.oi) {
                if (op.oi.date) {
                  hasDate = true;
                  if (op.oi.date < time) {
                    this.get("cs").log(op.oi.date - time);
                    send = true;
                  }
                }
              }
              //.si text ops have date a level higher than .oi json ops
              if (op.date) {
                hasDate = true;
                if (op.date < time) {
                  this.get("cs").log(op.date - time);
                  send = true;
                }
              }
              if (!hasDate) {
                //Dont send if no date, unless first op
                send = currentOp.v < 2;
              }
              if (justSource && op.p[0] !== "source") {
                send = false;
              }
            });
            if (send) {
              this.get("cs").log("sending", currentOp.v, currentOp.op[0].p[0], justSource, currentOp.op[0]);
              toSend.push(currentOp);
            } else {
              //this.get("cs").log("skipping",currentOp,justSource)
            }
          });
        }
        toSend.forEach(currentOp => {
          this.set("latestVersion", currentOp.v + 1);
          this.get("fromPlayer").push(currentOp);
          //Remove ops once sent
          const index = this.get('ops').indexOf(currentOp);
          if (index > -1) {
            this.get('ops').splice(index, 1);
          }
        });
        resolve();
      });
    },
    fastForwardsLatestVersion() {
      if (!Ember.isEmpty(this.get("ops"))) {
        this.get("ops").forEach(op => {
          if (this.get("latestVersion") < op.v) {
            this.set("latestVersion", op.v);
          }
        });
      }
      this.set("latestVersion", this.get("latestVersion") + 1);
      this.get("cs").log("fastforwarded to ", this.get("latestVersion"));
    },
    //Called when document is loaded from code-editor.js
    startTimer(editor) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.set("latestVersion", 0);
        //Load all the ops
        this.loadOps(0).then(() => {
          const lag = 10000;
          const interval = 100;
          let now;
          this.cleanUp();
          let justSource = true;
          //Set the latestVersion to the most recent op
          this.fastForwardsLatestVersion();
          //Clear all the ops
          this.set("ops", []);
          //Start counter to update ops every "lag" seconds
          this.get("cs").log("starting op timer", lag);
          this.set("updateOpsInterval", setInterval(() => {
            this.loadOps(this.get("latestVersion")).then(() => {
              justSource = false;
            });
          }, lag));
          //Start counter to check for ops to playback every "interval" seconds
          this.set("schedulerInteval", setInterval(() => {
            now = new Date().getTime() - lag;
            this.executeUntil(now, justSource);
          }, interval));
          resolve();
        });
      });
    },
    //Remove any ops that are not re-evaluations or code updates
    filterOps(toFilter) {
      let sourceOps = [];
      toFilter.forEach(ops => {
        if (ops.op !== undefined) {
          if (ops.op.length > 0) {
            if (ops.op[0].p[0] === "newEval" || ops.op[0].p[0] === "source") {
              sourceOps.push(ops);
            }
          }
        }
      });
      return sourceOps;
    },
    loadOps(from = 0) {
      const doc = this.get('doc');
      this.get('cs').log("loading ops", doc, from);
      return new Ember.RSVP.Promise((resolve, reject) => {
        let url = _environment.default.serverHost + "/documents/ops/" + doc;
        url = url + "?version=" + from;
        $.ajax({
          type: "GET",
          url: url
          //headers: {'Authorization': 'Bearer ' + this.get('sessionAccount.bearerToken')}
        }).then(res => {
          Ember.run(() => {
            if (res) {
              this.set('ops', this.filterOps(res.data));
            } else {
              this.set('ops', []);
            }
            this.get('cs').log("GOT OPS", from, this.get('ops').length);
            // for(const op of this.get('ops')) {
            //   this.get('cs').log(op)
            // }
            resolve(this.get('ops'));
          });
        }).catch(err => {
          Ember.run(() => {
            this.get("cs").log("op GET rejected", err);
            reject(err);
          });
        });
      });
    },
    //Clears all the timers
    cleanUp() {
      this.get("cs").log("cleaned up op player");
      this.set("latestVersion", 0);
      if (!Ember.isEmpty(this.get("schedulerInteval"))) {
        clearInterval(this.get("schedulerInteval"));
        this.set("schedulerInteval", null);
      }
      if (!Ember.isEmpty(this.get("updateOpsInterval"))) {
        clearInterval(this.get("updateOpsInterval"));
        this.set("updateOpsInterval", null);
      }
    },
    applyTransform(editor) {
      //this.get('cs').log("applying", this.get('opsToApply'))
      if (!Ember.isEmpty(this.get('opsToApply'))) {
        return this.get('parser').applyOps(this.get('opsToApply'), editor);
      } else {
        return [];
      }
    }
  });
});