define('ember-share-db/routes/techyard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);
      sessionStorage.setItem('doRefreshGuide', "true");
    }
  });
});