define('ember-share-db/components/project-tabs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    documentService: Ember.inject.service('documents'),
    cs: Ember.inject.service('console'),
    didReceiveAttrs() {
      this._super(...arguments);
    },
    actions: {
      createNewDocument() {
        this.get('cs').log("creating new tab", this.get('parent').id);
        const parent = this.get('parent');
        const name = "newTab" + this.get('tabs').length;
        const data = { name: name, isPrivate: true, source: "" };
        this.get('documentService').makeNewDoc(data, null, parent.id).then(doc => {
          this.get('onCreate')(doc.id);
        }).catch(error => {
          this.get('cs').log(error);
        });
      }
    }
  });
});