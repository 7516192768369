define('ember-share-db/services/project-stubs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    //We are allowed to assume that there is a learner object, otherwise all should
    //be self contained and recofigurable
    //Inputs should have a learner.addExamples
    //Outputs should have a learner.onOutput
    modules: ["hands", "poses", "faceMesh"],
    combine(input, model, output) {
      console.log(input, model, output);
      let type = "application/javascript";
      if (this.get("modules").includes(input)) {
        type = "module";
      }
      const inputCode = this.inputs()[input];
      const modelCode = this.models()[model];
      const outputCode = this.outputs()[output];
      let source = `<html>
<head>
`;
      source = source + inputCode["headers"];
      source = source + modelCode["headers"];
      source = source + outputCode["headers"];
      source = source + `</head>
<body>
`;
      source = source + inputCode["html"];
      source = source + modelCode["html"];
      source = source + outputCode["html"];
      source = source + `<script type = "` + type + `">
`;
      let inputJS = inputCode["js"];
      let modelJS = modelCode["js"];
      let outputJS = outputCode["js"];

      //If model added twice, output supersedes
      if (modelJS.includes("learner.addRegression") && outputJS.includes("learner.addRegression")) {
        modelJS = modelJS.replace("learner.addRegression", "//learner.addRegression");
      } else if (modelJS.includes("learner.addClassification") && outputJS.includes("learner.addClassification")) {
        modelJS = modelJS.replace("learner.addClassification", "//learner.addClassification");
      } else if (modelJS.includes("learner.addClassification") && outputJS.includes("learner.addRegression")) {
        modelJS = modelJS.replace("learner.addClassification", "//learner.addClassification");
      } else if (modelJS.includes("learner.addRegression") && outputJS.includes("learner.addClassification")) {
        modelJS = modelJS.replace("learner.addRegression", "//learner.addRegression");
      }

      if (output == "maxiSynthParamMapped") {
        inputJS = inputJS.replace("learner.y", "instruments.getMappedOutputs()");
      }

      source = source + "\n //// INPUT //// \n";
      source = source + inputJS;
      source = source + "\n //// MODEL //// \n";
      source = source + modelJS;
      source = source + "\n //// OUTPUT //// \n";
      source = source + outputJS;
      source = source + `
</script>
</body>
</html>`;
      console.log(source);
      return source;
    },
    outputs() {
      return {
        threeJS: {
          headers: `
   <script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/110/three.min.js"></script>
   <script type="x-shader/x-vertex" id="vertexShader">
        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    </script>
    <script type="x-shader/x-fragment" id="fragmentShader">
        uniform float time;
        void main() {
            float oscillationR = 0.5 + 0.5 * sin(time);
            float oscillationG = 0.5 + 0.5 * sin(time + 2.0);
            float oscillationB = 0.5 + 0.5 * sin(time + 4.0);
            vec3 color = vec3(oscillationR, oscillationG, oscillationB);
            gl_FragColor = vec4(color, 1.0);
        }
    </script>
      `,
          html: ``,
          js: `
    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;
    var renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    var geometry = new THREE.BoxGeometry();
    var vertexShader = document.getElementById('vertexShader').textContent;
    var fragmentShader = document.getElementById('fragmentShader').textContent;
    var material = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(0x00ff00) },
        time: { value: 1.0}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader
    });
    var cube = new THREE.Mesh(geometry, material);
    scene.add(cube);
    var time = 0
    var x = 0
    var y = 0
    learner.onOutput = (output)=> {
      time = output[0]
      x = output[1]
      y = output[2]
    }
    var animate = function () {
      requestAnimationFrame(animate);
      material.uniforms.time.value = time
      cube.rotation.x = x;
      cube.rotation.y = y;
      renderer.render(scene, camera);
    };
    animate();
    `,
          description: "Control Three.js shader sketches",
          label: "Three.js shaders"
        },

        p5: {
          headers: `<script src = "https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.7.0/p5.min.js"></script>`,
          html: `<div id = "sketch-holder"></div>`,
          js: `
    function setup() {
      var p5Canvas = createCanvas(640, 480);
      p5Canvas.parent('sketch-holder');
    }

    let r = 0
    let g = 0
    let b = 0

    function draw() {
      background(r,g,b)
    }

    learner.onOutput = (output)=> {
      r = output[0]*255
      g = output[1]*255
      b = output[2]*255
    }`,
          description: "Control p5.js sketches",
          label: "p5.js"
        },
        maximilian: {
          headers: `<script src = "../libs/maximilian.v.0.1.js"></script>`,
          html: `
   <!-- Maximilian code goes here -->
    <script id = "myAudioScript">
      var input = new Input('fromMain');
      var osc1 = new Maximilian.maxiOsc();
      var osc2 = new Maximilian.maxiOsc();
      function play() {
        //Get data from main thread
        var fromMain = input.getValue();
        var f1 = 100
        var f2 = 101
        var a = 0
        if(fromMain) {
          f1 = fromMain[0]*400
          f2 = fromMain[1]*300
          if(fromMain[2] > 0) {
            a = fromMain[2]
          }
        }
        return (osc1.saw(f1) + osc2.saw(f2)) * a;
      }
    </script>
      `,
          js: `
    let maxi;
    initAudioEngine().then((dspEngine)=>{ 
      maxi = dspEngine;
      //Get audio code from script element
      maxi.setAudioCode("myAudioScript");
    })
    learner.onOutput = (output)=> {
      maxi.send("fromMain", output);
    }
      `,
          description: "Control maximilian audio sketches",
          label: "maximilian.js"
        },
        maxiSynthParamMapped: {
          headers: `  <script src = "../libs/maxiInstruments.v.0.7.2.js"></script>
`,
          html: `  <div id = "synths"></div>
`,
          js: `
    var synth, sampler;
    const instruments = new MaxiInstruments();
    instruments.guiElement = document.getElementById("synths");

    instruments.loadModules().then(()=> {

      synth = instruments.addSynth();
      //Add yours here
      synth.mapped = ["lfoFrequency"];
      var em7 = [64, 67, 71, 74]
      var g7 = [62, 65, 67, 71]
      var cmaj9 = [60, 64, 67, 71]
      var fsharpm = [61, 64, 66, 69]
      var b7 = [59, 63, 66, 71]
      var synthSeq = [
        {s:0, l:96, p:em7},
        {s:96, l:96, p:g7},
        {s:192, l:96, p:cmaj9},
        {s:288, l:48, p:fsharpm},
        {s:288+48, l:48, p:b7},
      ];
      synth.setSequence(synthSeq);
      instruments.setLoop(96*4);
      learner.addRegression(instruments.getNumMappedOutputs(), false);
      learner.onOutput = (data)=> {
          instruments.updateMappedOutputs(data);
      }
    });
    `,
          description: "Use the MaxiInstruments library with a built-in synthesiser.",
          label: "MaxiSynth"
        },
        osc: {
          headers: ``,
          html: ``,
          js: `
    /*
    Download node repeater from https://github.com/Louismac/osc-repeater
    This sends the outputs vis websockets to the node program, which forwards the data via OSC to wherever you need it to be, defaulting to localhost:57120
    */

    let socket = new WebSocket("ws://localhost:8080");
    socket.onopen = function(e) {
      console.log("[open] Connection established");
    };

    //Callback for result
    learner.onOutput = (output)=> {
      //Send outputs to node
      console.log(output)
      socket.send(output);
    };
    `,
          description: "As you cannot OSC directly from a browser, this connects to a local Node.js program via webosckets then forwards the data out via OSC, where you can do with it what you will.",
          label: "OSC"
        },
        midiCC: {
          headers: `  <script src="../libs/MIMICMIDI.js"></script>
`,
          html: `  <div id = "midi-container">
    SETUP MIDI DEVICE:
    <button id = "btn-refresh">Refresh Devices</button>
  </div>
`,
          js: `
    //WARNING: WEBMIDI ONLY WORKS IN CHROME ATM
    const NUM_OUTPUTS = learner.y.length
    let midi = new MIDI();
    const devicesOutput = document.createElement("select");
    document.getElementById("midi-container").appendChild(devicesOutput);
    devicesOutput.onchange = ()=> {
     const index = parseInt(devicesOutput.selectedIndex);
     midi.setOutDevice(index, false);
    }

    const chans = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
    let selectedChannel = 1;
    const chanInput = document.createElement("select");
    document.getElementById("midi-container").appendChild(chanInput);
    chanInput.onchange = ()=> {
      const index = parseInt(chanInput.selectedIndex);
      midioutchannel = index;
    }
    chans.forEach((d, i)=> {
      const option = document.createElement("option");
      option.value = i;
      option.text = d;
      chanInput.appendChild(option);
    });

    document.getElementById("btn-refresh").onclick =  ()=> {
      let i, L = devicesOutput.options.length - 1;
      for(i = L; i >= 0; i--) {
       devicesOutput.remove(i);
      }
      const devices = midi.inDevices()
      devices.forEach((d, i)=> {
       const option = document.createElement("option");
       option.value = i;
       option.text = d;
       devicesOutput.appendChild(option);
      });
    }
    let prev = new Array(NUM_OUTPUTS).fill(0)
    //Callback for result
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    learner.onOutput = (output)=> {
      output.forEach((val, i)=> {
       val = clamp(Math.floor(val * 127),0,127);
       if(prev[i] !== val) {
         midi.control(i, val, selectedChannel);
       }
       prev[i] = val;
      });
    };
    `,
          description: "This uses WebMidi to send the output values as control changes. Note WebMidi is curently only supported in Chrome. Works with Regression Models",
          label: "MIDI CC"
        },
        midiNoteOn: {
          headers: `  <script src="../libs/MIMICMIDI.js"></script>
`,
          html: `  <div id = "midi-container">
    SETUP MIDI DEVICE:
    <button id = "btn-refresh">Refresh Devices</button>
  </div>
`,
          js: `
    //WARNING: WEBMIDI ONLY WORKS IN CHROME ATM
    const NUM_OUTPUTS = learner.y.length
    let midi = new MIDI();
    const devicesOutput = document.createElement("select");
    document.getElementById("midi-container").appendChild(devicesOutput);
    devicesOutput.onchange = ()=> {
     const index = parseInt(devicesOutput.selectedIndex);
     midi.setOutDevice(index, false);
    }

    const chans = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
    let selectedChannel = 1;
    const chanInput = document.createElement("select");
    document.getElementById("midi-container").appendChild(chanInput);
    chanInput.onchange = ()=> {
      const index = parseInt(chanInput.selectedIndex);
      midioutchannel = index;
    }
    chans.forEach((d, i)=> {
      const option = document.createElement("option");
      option.value = i;
      option.text = d;
      chanInput.appendChild(option);
    });

    document.getElementById("btn-refresh").onclick =  ()=> {
      let i, L = devicesOutput.options.length - 1;
      for(i = L; i >= 0; i--) {
       devicesOutput.remove(i);
      }
      const devices = midi.inDevices()
      devices.forEach((d, i)=> {
       const option = document.createElement("option");
       option.value = i;
       option.text = d;
       devicesOutput.appendChild(option);
      });
    }

    //USER PARAMETERS
    const NOTES = [60, 67, 62, 30];
    const USE_LENGTH = true;
    //Must be the same length
    const LENGTH = [400,400,400,400];

    let prev = 0;
    //Callback for result
    learner.onOutput = (output)=> {
      const val = NOTES[output[0]];
      const dur = LENGTH[output[0]];
      if(val !== prev) {
        console.log("off",prev,"on",val);
        midi.noteOff(prev, 127, selectedChannel);
        if(USE_LENGTH) {
          midi.noteOn(val, 127, selectedChannel, dur);
        } else {
          midi.noteOn(val, 127, selectedChannel);
        }

      }
      prev = val;
    };
    `,
          description: "This uses WebMidi to send a new MIDI note on when the class changes. Note WebMidi is curently only supported in Chrome. Works with Classification Models",
          label: "MIDI Note On"
        }
      };
    },
    models() {
      return {
        classification: {
          headers: `  <script src = "../libs/learner.v.0.5.js"></script>
`,
          "html": `  <div style = "width:400px;" id = "dataset"></div>
`,
          "js": `   const learner = new Learner();
    //Add GUI if you want, give id of parent element
    learner.addGUI(document.getElementById("dataset"));
    learner.addClassifier(3);
`,
          description: "Build a model that learns to predict discrete states",
          label: "Classification Model"
        },
        regression: {
          headers: `  <script src = "../libs/learner.v.0.5.js"></script>
`,
          html: `  <div style = "width:400px;" id = "dataset"></div>
`,
          js: `  const learner = new Learner();
  //Add GUI if you want, give id of parent element
  learner.addGUI(document.getElementById("dataset"));
  learner.addRegression(3);
`,
          description: "Build a model that learns to predict continuous values. Uses TensorflowJS 3.0.0 for neural networks",
          label: "Regression Model"
        }
      };
    },
    inputs() {
      return {
        mouse: {
          headers: ``,
          html: `  <div style = "width:400px;height:200px;border:5px solid blue;text-align:center" id = "input">INPUT CLICKS IN THIS BOX</div>
`,
          js: `  const recordXY = (event)=> {
    learner.newExample([event.clientX, event.clientY], learner.y);
  }
  document.getElementById("input").addEventListener("click", recordXY);
`,
          description: "X and Y coordinates for mouse clicks",
          label: "Mouse input"
        },
        mfccs: {
          headers: `  <script src = "../libs/maximilian.v.0.1.js"></script>
`,
          html: `  <script id = "myAudioScript">
    var fft, mfcc, coeffs;
    var bins = 2048;
    var hopPercentage = 0.5;
    var numCoeffs = 13;
    var mags, phases;

    //Create output to send back to main thread
    this.createOutput("fromAudio", numCoeffs);

    fft = new Maximilian.maxiFFTAdaptor();
	  fft.setup(bins * 2, Math.floor(bins * 2 * hopPercentage), bins * 2);
    mags = fft.getMagnitudesAsJSArray();
	  phases = fft.getPhasesAsJSArray();

   	mfcc = new Maximilian.maxiMFCCAdaptor();
	  mfcc.setup(bins, 40, numCoeffs, 20, 20000);
	  coeffs = new Float64Array(numCoeffs);
    var play = (inputs)=> {
      var sig = inputs ? inputs:0;
      if (fft.process(sig, Maximilian.maxiFFTModes.WITH_POLAR_CONVERSION)) {
        coeffs = mfcc.mfcc(fft.getMagnitudesAsJSArray());
        this.send("fromAudio", 1, coeffs);
      }
      this.send("fromAudio", 0, coeffs);
      return inputs;
    }
  </script>
  <canvas id="plot" width="400" height="100" style="border:1px solid #d3d3d3;"></canvas>
`,
          js: ` let maxi;
  var plot = document.getElementById("plot");
  var ctx = plot.getContext("2d");
  ctx.fillStyle = "blue";
  let w = plot.width
  let h = plot.height
  let scale = h/2
  initAudioEngine().then((dspEngine)=>{
    maxi = dspEngine;
    maxi.connectMediaStream();
    //Get audio code from script element
    maxi.setAudioCode("myAudioScript");
    //Callback to receive data from audio worklet
    maxi.onInput = (id, data)=> {
      const mfcc = Object.keys(data).map(x=>data[x])
      ctx.clearRect(0, 0, w, h);
      let barWidth = w / mfcc.length
      for(let i = 0; i < mfcc.length; i++) {
        let val = mfcc[i]*scale
        ctx.fillRect(barWidth*i,h-val, barWidth,val);
      }
      learner.newExample(mfcc, learner.y)
    }
  })
`,
          description: "MFCCs with Maximilian, a great timbral feature for classifying audio",
          label: "MFCCs"
        },
        faceMesh: {
          headers: `<script src="https://cdn.jsdelivr.net/npm/@tensorflow/tfjs@3.0.0/dist/tf.min.js"></script>
  <script src = "https://unpkg.com/@tensorflow/tfjs-backend-wasm@3.0.0/dist/tf-backend-wasm.js"></script>
<script src = "https://cdn.jsdelivr.net/npm/@tensorflow-models/facemesh"></script>
`,
          html: `   <p id = "loading-label">Camera and Model Loading...</p>
  <canvas id="canvas" width="640" height="480"></canvas>
	<video id="video" width="640" height="480" style="display: none"></video>
`,
          js: `      var model, video,canvas, ctx;
    async function main() {
     const state = {
        backend: 'wasm',
        maxFaces: 1,
      };
      await tf.setBackend(state.backend);
      // Load the MediaPipe facemesh model.
      model = await facemesh.load();

      video = document.getElementById('video');
      canvas = document.getElementById('canvas');
      ctx = canvas.getContext('2d');
      console.log("loaded model");
      // Create a webcam capture
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
          video.srcObject = stream;
          document.getElementById("loading-label").style.display = "none";
          video.play();
          video.onloadeddata = async()=> {
            console.log("on loaded video data")
            console.log("loading model...");
			getFaces()
          };
        });
      }
    }

    const getFaces = async ()=> {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const predictions = await model.estimateFaces(document.querySelector("video"));
      if (predictions.length > 0) {
        let data = []
        for (let i = 0; i < predictions.length; i++) {
          const keypoints = predictions[i].scaledMesh;
          // Log facial keypoints.
          for (let i = 0; i < keypoints.length; i++) {
            const x = keypoints[i][0];
            const y = keypoints[i][1];
            data.push(x)
            data.push(y)
            ctx.beginPath();
            ctx.arc(x, y, 1 /* radius */, 0, 2 * Math.PI);
            ctx.fill();
          }
        }
        learner.newExample(data, learner.y);
      }
      window.requestAnimationFrame(getFaces);
    }

    main();
`,
          description: "Uses Tensorflow model to get a mesh (many points!) of a face. Works for multiple faces at once",
          label: "Faces"
        },
        mobileNet: {
          headers: `  <script src="https://cdn.jsdelivr.net/npm/@tensorflow/tfjs@1.0.1"></script>
   <script src="https://cdn.jsdelivr.net/npm/@tensorflow-models/mobilenet@1.0.0"> </script>
`,
          html: `  <p id = "loading-label">Camera and Model Loading...</p>
 <canvas id="canvas" width="640" height="480"></canvas>
 <video id="video" width="640" height="480" style="display: none"></video>
`,
          js: `   let model;
    let video = document.getElementById('video');
    let canvas = document.getElementById('canvas');
    let ctx = canvas.getContext('2d');

    /// Load the model.
	  mobilenet.load().then(m => {
      model = m;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
          video.srcObject = stream;
          document.getElementById("loading-label").style.display = "none";
          video.play();
          video.onloadeddata = (event) => {
            console.log("loaded model");
            getFeatures();
          };
        });
      }
      const getFeatures = ()=> {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const logits = model.infer(video);
        // Add features to dataset / input, convert from Typed Array
        let inputs = logits.dataSync();
        inputs = Array.prototype.slice.call(inputs);
        learner.newExample(inputs, learner.y);
        window.requestAnimationFrame(getFeatures);
       }
    });
`,
          description: "MobileNet features. This uses a pretrained model (trained on the ImageNet dataset) to provide 1000 features from video that will be useful for typical image classification tasks",
          label: "Objection Detection"
        },
        poses: {
          headers: `  <script src="https://cdn.jsdelivr.net/npm/@tensorflow/tfjs"></script>
   <script src="https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-webgl"></script>
   <script src="https://cdn.jsdelivr.net/npm/@tensorflow-models/pose-detection"></script>
`,
          html: `   <p id = "loading-label">Camera and Model Loading...</p>
    <canvas id="canvas" width="640" height="480"></canvas>
		<video id="video" width="640" height="480" style="display: none"></video>
`,
          js: `   var model, video,canvas, ctx;
    async function main() {
     const state = {
        backend: 'webgl',
      };
      await tf.setBackend(state.backend);
      const detectorConfig = {modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING};
	    const detector = await poseDetection.createDetector(poseDetection.SupportedModels.MoveNet, detectorConfig);

      video = document.getElementById('video');
      canvas = document.getElementById('canvas');
      ctx = canvas.getContext('2d');
      console.log("loaded model");
      // Create a webcam capture
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
          video.srcObject = stream;
          document.getElementById("loading-label").style.display = "none";
          video.play();
          video.onloadeddata = async()=> {
            console.log("on loaded video data")
            console.log("loading model...");
			      getBodies()
          };
        });
      }

      const getBodies = async ()=> {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const predictions = await detector.estimatePoses(video);
        if (predictions.length > 0) {
          let data = []
          for (let i = 0; i < predictions.length; i++) {
            const keypoints = predictions[i].keypoints;
            // Log facial keypoints.
            for (let i = 0; i < keypoints.length; i++) {
              const x = keypoints[i].x;
              const y = keypoints[i].y;
              const score = keypoints[i].score;
              data.push(x)
              data.push(y)
              ctx.beginPath();
              ctx.arc(x, y, 6, 0, 2 * Math.PI);
              ctx.fill();
            }
          }
          learner.newExample(data, learner.y);
        }
        window.requestAnimationFrame(getBodies);
      }
    }

    main();
`,
          description: "Tensorflow's MoveNet Model. This is Tensorflow JS's preferred post model and uses a pretrained model to get 17 skeleton points",
          label: "Body Pose"
        },
        hand: {
          headers: ` <script src="https://cdn.jsdelivr.net/npm/@mediapipe/hands"></script>
  <script src="https://cdn.jsdelivr.net/npm/@tensorflow/tfjs"></script>
  <script src="https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-webgl"></script>
  <script src="https://cdn.jsdelivr.net/npm/@tensorflow-models/hand-pose-detection"></script>
`,
          html: ` <p id = "loading-label">Camera and Model Loading...</p>
  <canvas id="canvas" width="640" height="480"></canvas>
  <video id="video" width="640" height="480" style="display: none"></video>
`,
          js: `//INPUT////
    //https://github.com/tensorflow/tfjs-models/tree/master/hand-pose-detection
    var model, detector, video,canvas, ctx;
    async function main() {
      const model = handPoseDetection.SupportedModels.MediaPipeHands;
      const detectorConfig = {
        runtime: 'mediapipe',
        solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/hands',
        modelType: 'full'
      }
      detector = await handPoseDetection.createDetector(model, detectorConfig);

      video = document.getElementById('video');
      canvas = document.getElementById('canvas');
      ctx = canvas.getContext('2d');
      console.log("loaded model");
      // Create a webcam capture
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
          video.srcObject = stream;
          document.getElementById("loading-label").style.display = "none";
          video.play();
          video.onloadeddata = async()=> {
            console.log("on loaded video data")
            console.log("loading model...");
            getHands()
          };
        });
      }
    }

    //points per hand
    const numPts = 42;
    let data = new Array(numPts* 2).fill(0);
    const getHands = async ()=> {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      data = new Array(numPts* 2).fill(0);
      const predictions = await detector.estimateHands(video);
      if (predictions.length > 0) {
        for (let i = 0; i < predictions.length; i++) {
          let hand = 0;
          if(predictions[i].handedness == "Right") {
            ctx.fillStyle = "red";
          } else {
            hand = 1;
            ctx.fillStyle = "blue";
          }
          const keypoints = predictions[i].keypoints;
          for (let j = 0; j < keypoints.length; j++) {
            const x = keypoints[j].x;
            const y = keypoints[j].y;
            data[(numPts * hand) + j] = x;
            data[(numPts * hand) + j + 1] = y;
            ctx.beginPath();
            ctx.arc(x, y, 5, 0, 2 * Math.PI);
            ctx.fill();
          }
        }
      }
      learner.newExample(data, learner.y);
      window.requestAnimationFrame(getHands);
    }

    main();
`,
          description: "Using Tensorflow.js's built in HandPose model. Tracks multiple hands with 42 individual coordinates each",
          label: "Hand Pose"
        },
        ble: {
          headers: ``,
          html: `  WEBBLE only works in Chrome! <br>
  You will need to get the right UUIDs for your device
  <br>
  <button onclick="onConnectDevice()">Connect Device</button>
  <br>
`,
          js: `  function onConnectDevice() {
    //Add in the appropriate service UUID
    let serviceUuid = "0000180d-0000-1000-8000-00805f9b34fb"
    if (serviceUuid.startsWith('0x')) {
      serviceUuid = parseInt(serviceUuid);
    }
	//Add in the appropriate characteristic UUID
    let characteristicUuid = "00002a37-0000-1000-8000-00805f9b34fb";
    //00001142-0000-1000-8000-00805f9b34fb
    //"00001143-0000-1000-8000-00805f9b34fb
    if (characteristicUuid.startsWith('0x')) {
      characteristicUuid = parseInt(characteristicUuid);
    }

    console.log('Requesting Bluetooth Device...');
    navigator.bluetooth.requestDevice({filters: [{services: [serviceUuid]}]})
    .then(device => {
      console.log('Connecting to GATT Server...');
      return device.gatt.connect();
    })
    .then(server => {
      console.log('Getting Service...');
      return server.getPrimaryService(serviceUuid);
    })
    .then(service => {
      console.log('Getting Characteristic...');
      return service.getCharacteristic(characteristicUuid);
    })
    .then(characteristic => {
      console.log('> Characteristic UUID:  ' + characteristic.uuid);
      console.log('> Broadcast:            ' + characteristic.properties.broadcast);
      console.log('> Read:                 ' + characteristic.properties.read);
      console.log('> Write w/o response:   ' +
        characteristic.properties.writeWithoutResponse);
      console.log('> Write:                ' + characteristic.properties.write);
      console.log('> Notify:               ' + characteristic.properties.notify);
      console.log('> Indicate:             ' + characteristic.properties.indicate);
      console.log('> Signed Write:         ' +
        characteristic.properties.authenticatedSignedWrites);
      console.log('> Queued Write:         ' + characteristic.properties.reliableWrite);
      console.log('> Writable Auxiliaries: ' +
        characteristic.properties.writableAuxiliaries);
      characteristic.addEventListener('characteristicvaluechanged',
          handleCharacteristicChanged);
      characteristic.startNotifications();
    })
    .catch(error => {
      console.log('Argh! ' + error);
    });

    function handleCharacteristicChanged(event) {
      //Unpack the data
      const val = event.target.value.getUint8(0)
      learner.newExample(val, learner.y);
    }
`,
          description: "Connect a BLE device using WebBLE. Only works in Chrome.",
          label: "BLE Device"
        },
        osc: {
          headers: ``,
          html: ``,
          js: `      /*
    Download node repeater from https://github.com/Louismac/osc-repeater
    This picks up the OSC messages and forwards them onto the mimic site using websockets
    */

    let socket = new WebSocket("ws://localhost:8080");
    socket.onopen = function(e) {
      console.log("[open] Connection established");
      socket.onmessage = function(e) {
        let msg = JSON.parse(e.data);
        //Drop address
        msg.shift();
        learner.newExample(msg, learner.y);
      }
    };
`,
          description: "Record values sent over OSC (via websockets) into a dataset",
          label: "OSC"
        },
        androidSensors: {
          headers: ``,
          html: ``,
          js: `      /*

    1. Get the oscHook app
    https://play.google.com/store/apps/details?id=com.hollyhook.oscHook
    2. Download node repeater from https://github.com/Louismac/osc-repeater
    This picks up the OSC messages and forwards them onto the mimic site using websockets
    3. Run the node app on your local machine, and the android app on your phone using port 12345 and the IP address of your local machine.
    */

    let socket = new WebSocket("ws://localhost:8080");
    socket.onopen = function(e) {
      console.log("[open] Connection established");
      socket.onmessage = function(e) {
        let msg = JSON.parse(e.data);
        if(msg[0] == "/oscHook")
        {
          //Get gravity and acceleration
          msg = msg.slice(2, 8);
          learner.newExample(msg, learner.y);
        }
      }
    };
`,
          description: `Connect the motion sensors on your Android. Requires free oscHook app (https://play.google.com/store/apps/details?id=com.hollyhook.oscHook), values sent over OSC (via websockets)`,
          label: "Android Sensors"
        },
        iPhoneSensor: {
          headers: ``,
          html: ``,
          js: `  /*
    Get the Motion Sender app
    1.  https://apps.apple.com/gb/app/motionsender/id1315005698
    2. Download node repeater from https://github.com/Louismac/osc-repeater
    This picks up the OSC messages and forwards them onto the mimic site using websockets
    3. Run the node app on your local machine, and the iPhone app on your phone using port 12345 and the IP address of your local machine.
    */

    let socket = new WebSocket("ws://localhost:8080");
    socket.onopen = function(e) {
      console.log("[open] Connection established");
      socket.onmessage = function(e) {
        let msg = JSON.parse(e.data);
        if(msg[0] == "/wek/inputs")
        {
          //Drop address
           msg.shift();
           learner.newExample(msg, learner.y);
        }
      }
    };
`,
          description: "Connect the motion sensors on your iphone. Requires free Motion sender app, values sent over OSC (via websockets)",
          label: "iPhone Sensors"
        },

        audioFeatures: {
          headers: `  <script src = "../libs/MMLL.js"></script>
`,
          html: `  <div id = "mmll"></div>
`,
          js: `   let i;
    const audioblocksize = 256; //lowest latency possible
    let spectralCentroidExtractor, sensoryDissonanceExtractor, spectralPercentileExtractor;
    let dissonace, brightness, spectralPercentile;
    let percentile = 0.8;
    const setup = (sampleRate)=> {
      sensoryDissonanceExtractor = new MMLLSensoryDissonance(sampleRate);
      spectralCentroidExtractor = new MMLLSpectralCentroid();
      spectralPercentileExtractor = new MMLLSpectralPercentile(sampleRate);
    };
    const callback = (input, output, n)=> {
      //Get audio features
      dissonance = sensoryDissonanceExtractor.next(input.monoinput);
      brightness = spectralCentroidExtractor.next(input.monoinput) * 8;
      spectralPercentile = spectralPercentileExtractor.next(input.monoinput);
      const data = [dissonance, brightness, spectralPercentile];
      //Input data
      learner.newExample(data, learner.y);
      //Dont output the mic (feedback!)
      isMic = gui.webaudio.inputtype == 1;
      if(!isMic)
      {
        for (i = 0; i < n; ++i)
        {
          output.outputL[i] = input.inputL[i];
          output.outputR[i] = input.inputR[i];
        }
      }
    }

    const gui = new MMLLBasicGUISetup(callback, setup, audioblocksize, true, true, document.getElementById("mmll"));
`,
          description: "An example of using Nick Collins MMLL.js library to get a range of audio features from either the microphone or audio file. Features include spectral percentile, sensory dissonance and brightness (spectral centroid)",
          label: "MMLL Audio Features"
        }
      };
    }
  });
});