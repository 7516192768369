define("ember-share-db/router", ["exports", "ember-share-db/config/environment", "ember-tracker/mixins/google-analytics-route"], function (exports, _environment, _googleAnalyticsRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend(_googleAnalyticsRoute.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("login");
    this.route("code-editor", { path: "/code/:document_id" });
    this.route("documents", { path: "/d/:search/:page/:sort" });
    this.route("password-reset");
    this.route("about");
    this.route("terms");
    this.route("getting-started", { path: "/getting-started/:topic" });
    this.route("guides", { path: "/guides/:topic" });
    this.route("examples", { path: "/examples/:topic" });
    this.route("api", { path: "/api/*endpoint" });
    this.route("inputs");
    this.route("outputs");
    this.route("people");
    this.route("nime2020");
    this.route("futurelearn");
    this.route("crash-course");
    this.route("techyard");
    this.route("courses");
    this.route("course", { path: "/course/:course_id" }, function () {
      this.route("course-overview", { path: "/overview" });
      this.route("course-unit", { path: "/:course_unit_id" });
    });
    // this.route("course", { path: "/course/:course_id/:course_unit_id" });
    this.route('add-cors', { path: "/addcors" });
    this.route('project-generator');
  });

  exports.default = Router;
});