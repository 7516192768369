define('ember-share-db/routes/documents', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sessionAccount: Ember.inject.service("session-account"),
    cors: Ember.inject.service("cors"),
    cs: Ember.inject.service('console'),
    setupController: function (controller, model) {
      this._super(controller, model);
      sessionStorage.setItem('doRefreshGuide', "true");
      if (controller) {
        this.get('cs').log("setupController document", model.docs.query.filter.sortBy);
        controller.send('updateSelectedFilter', model.docs.query.filter.sortBy);
        controller.send('flashResults');
      }
    },
    afterModel: (model, transition) => {
      //Do we need to refresh to add in cors headers? (needed for gallery projects)
      //This will happen when using localhost4200 (theres no sharedarraybuffers)
      if (typeof SharedArrayBuffer === 'undefined' && Date.now() - sessionStorage.getItem('lastCodeReset') > 2000) {
        sessionStorage.setItem('lastCodeReset', Date.now());
        sessionStorage.setItem('doRefreshGuide', "true");
        console.log("REFRESH!");
        const p = transition.to.params;
        const URL = _environment.default.localOrigin + "/d/" + p["search"] + "/" + p["page"] + "/" + p["sort"];
        window.location.href = URL;
      }
    },
    model(params) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        // this.get("cors").enterCode()
        let currentUserId = this.get('sessionAccount').currentUserId;
        let currentUserName = this.get('sessionAccount').currentUserName;
        this.get('cs').log("document model", currentUserId, currentUserName, params.sort);
        const sort = params.sort ? params.sort : "views";
        const search = params.search ? params.search : " ";
        let filter = {
          filter: {
            search: search,
            page: params.page,
            sortBy: sort
          }
        };
        this.get("cs").log("filter", filter.filter);
        if (Ember.isEmpty(currentUserId)) {
          if (!Ember.isEmpty(currentUserName)) {
            this.get('cs').log("has name but doesnt have currentUserId", currentUserName);
            this.get('sessionAccount').getUserFromName().then(() => {
              this.get('sessionAccount').updateOwnedDocuments().then(() => {
                filter.filter.currentUser = this.get('sessionAccount').currentUserId;
                this.get('cs').log("document model got id", filter.filter.currentUser);
                this.get('store').query('document', filter).then((res, reject) => {
                  resolve({ docs: res, filter: filter.filter });
                });
              }).catch(err => {
                this.get('cs').log('updateOwnedDocuments');
              });
            }).catch(err => {
              this.get('cs').log('error getUserFromName');
              filter.filter.currentUser = "";
              this.get('store').query('document', filter).then((res, reject) => {
                resolve({ docs: res, filter: filter.filter });
              }).catch(err => {
                this.get('cs').log('error query');
                reject(err);
              });
            });
          } else {
            this.get('cs').log("finding documents", currentUserId, currentUserName, this.get('store'), filter);
            this.get('store').query('document', filter).then((res, reject) => {
              this.get('cs').log("found documents");
              resolve({ docs: res, filter: filter.filter });
            }).catch(err => {
              this.get('cs').log("error finding documents");
              reject(err);
            });
          }
        } else if (!Ember.isEmpty(currentUserName)) {
          this.get('store').query('document', filter).then((res, reject) => {
            resolve({ docs: res, filter: filter.filter });
          }).catch(err => reject(err));
        } else {
          filter.filter.currentUser = "";
          this.get('store').query('document', filter).then((res, reject) => {
            this.get('cs').log("c");
            resolve({ docs: res, filter: filter.filter });
          });
        }
      });
    },
    actions: {
      error(error, transition) {
        this.get('cs').log("ERROR transitioning document route");
        const err = error.errors ? error.errors : error;
        if (error) {
          if (err.status === '404') {
            this.get('cs').log("ERROR 404");
            this.replaceWith('application');
          } else {
            return true;
          }
        }
      }
    }
  });
});