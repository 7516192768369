define("ember-share-db/components/guide-tile", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mediaQueries: Ember.inject.service(),
    guide: "",
    description: Ember.computed("guide", function () {
      return this.get("guide").desc;
    }),
    name: Ember.computed("guide", function () {
      return this.get("guide").name;
    }),
    author: Ember.computed("guide", function () {
      return this.get("guide").author;
    }),
    isSelected: false,
    store: Ember.inject.service("store"),
    index: 0,
    colourId: Ember.computed("index", function () {
      return "tile" + this.get("index") % 5;
    }),
    actions: {
      onClick() {
        if (this.onClick) {
          this.get("onClick")(this.get("guide"));
        }
      },
      onover() {
        this.set("isSelected", true);
      },
      onout() {
        this.set("isSelected", false);
      }
    }
  });
});