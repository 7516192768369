define('ember-share-db/components/base-token', ['exports', 'ember-share-db/templates/components/base-token'], function (exports, _baseToken) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //Component properties
    layout: _baseToken.default, // For more info: https://discuss.emberjs.com/t/layout-property-for-declaring-html-in-component/12844/2
    classNames: ['uncharted-token'],
    classNameBindings: ['isSelected:uncharted-selected-token'],
    didUpdateAttrs() {
      this._super(...arguments);
    },
    // Properties
    token: null,
    index: null,
    selectedTokenIndex: null,
    canDelete: true,
    // State
    isSelected: Ember.computed('index', 'selectedTokenIndex', function () {
      return this.get('index') === this.get('selectedTokenIndex');
    }),

    // Actions
    actions: {
      removeToken() {
        this.removeToken();
      },
      selectToken() {
        console.log("CAN DELETE?", this.get('canDelete'));
        if (!this.get('canDelete')) {
          console.log("mouseDown");
          this.mouseDown();
        }
      },
      onDelete() {
        this.removeToken();
      }
    }
  });
});