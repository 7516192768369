define('ember-share-db/services/media-queries', ['exports', 'ember-cli-media-queries/services/media-queries'], function (exports, _mediaQueries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mediaQueries.default.extend({
    media: Ember.computed(() => {
      return {
        xs: '(max-width: 575px)',
        sm: '(min-width: 576px) and (max-width: 767px)',
        md: '(min-width: 768px) and (max-width: 991px)',
        lg: '(min-width: 992px) and (max-width: 1999px)',
        xl: '(min-width: 1200px)',
        mobile: '(max-width: 767px)',
        desktop: '(min-width: 768px)',
        embeddedDesktop: '(min-width: 450px)',
        docs: '(min-width: 1050px)',
        burger: '(max-width: 450px)'
      };
    })
  });
});