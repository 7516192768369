define('ember-share-db/models/document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    source: _emberData.default.attr('string'),
    owner: _emberData.default.attr('string'),
    ownerId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    created: _emberData.default.attr('date'),
    isPrivate: _emberData.default.attr('boolean'),
    readOnly: _emberData.default.attr('boolean'),
    documentId: _emberData.default.attr('string'),
    lastEdited: _emberData.default.attr('date'),
    assets: _emberData.default.attr(),
    tags: _emberData.default.attr(),
    forkedFrom: _emberData.default.attr('string'),
    savedVals: _emberData.default.attr(),
    newEval: _emberData.default.attr(),
    stats: _emberData.default.attr(),
    flags: _emberData.default.attr('number'),
    assetQuota: _emberData.default.attr('number'),
    dontPlay: _emberData.default.attr('boolean'),
    isCollaborative: _emberData.default.attr('boolean'),
    children: _emberData.default.attr(),
    collaboratorCode: _emberData.default.attr('string'),
    parent: _emberData.default.attr('string'),
    type: _emberData.default.attr('string')
  });
});