define('ember-share-db/controllers/examples', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    topic: "",
    cs: Ember.inject.service('console'),
    url: _environment.default.localOrigin,
    isExample: Ember.computed('model', function () {
      this.get('cs').log("isExample", this.get('model'), Array.isArray(this.get('model')));
      return !Array.isArray(this.get('model'));
    }),
    isMagnet: Ember.computed('model', function () {
      this.get('cs').log("example model", this.get('model'));
      return this.get('model').id == "magnet";
    }),
    isBBcut: Ember.computed('model', function () {
      this.get('cs').log(this.get('model'));
      return this.get('model').id == "bbcut";
    }),
    isEvolib: Ember.computed('model', function () {
      this.get('cs').log(this.get('model'));
      return this.get('model').id == "evolib";
    }),
    isMario: Ember.computed('model', function () {
      this.get('cs').log(this.get('model'));
      return this.get('model').id == "mario";
    }),
    isMerk: Ember.computed('model', function () {
      this.get('cs').log(this.get('model'));
      return this.get('model').id == "merk";
    }),
    isMarkov: Ember.computed('model', function () {
      this.get('cs').log(this.get('model'));
      return this.get('model').id == "markov";
    }),
    isAudiotrig: Ember.computed('model', function () {
      return this.get('model').id == "audio-trigger";
    }),
    isFace: Ember.computed('model', function () {
      return this.get('model').id == "facesynth";
    }),
    isRhythm: Ember.computed('model', function () {
      return this.get('model').id == "rhythm-remixer";
    }),
    isConceptular: Ember.computed('model', function () {
      return this.get('model').id == "conceptular";
    }),
    isSpec: Ember.computed('model', function () {
      return this.get('model').id == "specdelay";
    }),
    isLyric: Ember.computed('model', function () {
      return this.get('model').id == "lyrics";
    }),
    isSun: Ember.computed('model', function () {
      return this.get('model').id == "sun-on-your-skin";
    }),
    isKicks: Ember.computed('model', function () {
      return this.get('model').id == "kick-classifier";
    }),
    isSpaceDrum: Ember.computed('model', function () {
      return this.get('model').id == "space-drum";
    }),
    isAutoPilot: Ember.computed('model', function () {
      return this.get('model').id == "auto-pilot";
    }),
    actions: {
      onClick(example) {
        this.transitionToRoute('examples', example.id);
      }
    }
  });
});