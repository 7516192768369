define('ember-share-db/routes/index', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel() {

      // this.replaceWith('about');
      window.location.href = _environment.default.localOrigin + "/about";
    }
  });
});