define('ember-share-db/components/filter-bar', ['exports', 'ember-railio-grid/components/filter-bar'], function (exports, _filterBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _filterBar.default;
    }
  });
});