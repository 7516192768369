define("ember-share-db/controllers/guides", ["exports", "ember-share-db/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    topic: "",
    cs: Ember.inject.service("console"),
    url: _environment.default.localOrigin,
    isGuide: Ember.computed("model", function () {
      this.get("cs").log(this.get("model"), Array.isArray(this.get("model")));
      return !!this.get("model").id;
    }),
    sortedCourses: Ember.computed("model.courses.@each.published-at", function () {
      return this.get("model.courses").sortBy("published-at").reverse();
    }),
    isRAPIDMIX: Ember.computed("model", function () {
      this.get("cs").log(this.get("model"));
      return this.get("model").id == "RAPIDMIX";
    }),
    isMMLL: Ember.computed("model", function () {
      this.get("cs").log(this.get("model"));
      return this.get("model").id == "mmll";
    }),
    isEvolib: Ember.computed("model", function () {
      this.get("cs").log(this.get("model"));
      return this.get("model").id == "evolib";
    }),
    isMaxim: Ember.computed("model", function () {
      this.get("cs").log(this.get("model"));
      return this.get("model").id == "maximJS";
    }),
    isKadenze: Ember.computed("model", function () {
      this.get("cs").log(this.get("model"));
      return this.get("model").id == "kadenze";
    }),
    isLearner: Ember.computed("model", function () {
      this.get("cs").log(this.get("model"));
      return this.get("model").id == "learner";
    }),
    isMaxiInstruments: Ember.computed("model", function () {
      this.get("cs").log("IS MAXI", this.get("model").id == "maxi-instruments");
      return this.get("model").id == "maxi-instrument";
    }),
    isRecording: Ember.computed("model", function () {
      return this.get("model").id == "recording";
    }),
    isColab: Ember.computed("model", function () {
      return this.get("model").id == "colab";
    }),
    isSupervisedML: Ember.computed("model", function () {
      return this.get("model").id == "supervised-ml";
    }),
    actions: {
      onClick(guide) {
        this.transitionToRoute("guides", guide.id);
        window.scrollTo(0, 0);
      }
    }
  });
});