define('ember-share-db/components/data-row', ['exports', 'ember-railio-grid/components/data-row'], function (exports, _dataRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dataRow.default;
    }
  });
});