define('ember-share-db/services/console', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    output: "",
    init() {
      this._super(...arguments);
      this.set('observers', []);
      console.log("DEBUG INIT", _environment.default.debugConsole);
      this.setDebugMode(_environment.default.debugConsole);
    },
    clearObservers() {
      this.set('observers', []);
    },
    clear() {
      this.set('output', []);
      const observers = this.get('observers');
      for (let i = 0; i < observers.length; i++) {
        observers[i].update();
      }
    },
    append(msg) {
      this.get('output').push(msg);
      const observers = this.get('observers');
      for (let i = 0; i < observers.length; i++) {
        observers[i].update();
      }
    },
    getOutput() {
      //let text = "[\"Console Ouput\"" + this.get("output") + "]"
      // console.log(text)
      //Instead of JSON.stringify on the client side, we use a custom prettyPrint function
      // var parseJSON = JSON.parse(text);
      // text = JSON.stringify(parseJSON, undefined, 4)
      // text = text.replace(/",\n}"/g, '}')
      // text = "{\"hello\":{\"hello\":{\"hello\":[1,2,3,4]}}}"
      //text = JSON.parse(text);
      return this.get("output");
    },
    logToScreen() {
      const msgs = arguments;
      for (let i = 0; i < msgs.length; i++) {
        let str = msgs[i];
        this.append(str);
      }
    },
    log() {},
    setDebugMode(debugEnabled) {
      if (debugEnabled && typeof console != 'undefined') {
        this.set('log', console.log.bind(console));
      } else {
        this.set('log', function (message) {});
      }
    }

  });
});