define('ember-share-db/components/about-description', ['exports', 'ember-share-db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mediaQueries: Ember.inject.service(),
    colours: Ember.computed(() => {
      return ["#ED3D05F2", "#FFCE00F2", "#0ED779F2", "#F79994F2", "#4D42EBF2"];
    }),
    docURL: _environment.default.localOrigin + "/getting-started/beginner",
    didRender() {
      this._super(...arguments);
      let colour1 = Math.floor(Math.random() * 5);
      let colour2 = Math.floor(Math.random() * 5);
      while (colour2 == colour1) {
        colour2 = Math.floor(Math.random() * 5);
      }
      document.getElementById('about-overlay-title').style['background-color'] = this.get('colours')[colour1];
      const desc = document.getElementById("about-overlay-desc");
      if (!Ember.isEmpty(desc)) {
        desc.style['background-color'] = this.get('colours')[colour2];
      }
    }
  });
});