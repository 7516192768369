define("ember-share-db/components/collapsable-unit-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    collapsedClassname: "collapsed",
    didRender() {
      const collabsableUnitsEl = document.getElementById("collapsable-units");
      const scrollHeight = collabsableUnitsEl.scrollHeight;
      collabsableUnitsEl.style.maxHeight = `${scrollHeight}px`;
    },
    actions: {
      onClick() {
        if (this.collapsedClassname) {
          this.set("collapsedClassname", "");
        } else {
          this.set("collapsedClassname", "collapsed");
        }

        this.set("scrollHeight", document.getElementById("collapsable-units").scrollHeight);
      }
    }
  });
});