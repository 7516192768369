define("ember-share-db/helpers/join", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function join([arr, str]) {
    if (!arr) return "";
    return arr.join(str);
  }

  exports.default = Ember.Helper.helper(join);
});