define("ember-share-db/helpers/contentsToHtml", ["exports", "@portabletext/to-html"], function (exports, _toHtml) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function contentsToHtml([contents]) {
    console.log("contents", contents);
    return (0, _toHtml.toHTML)(contents, {
      components: {
        /* optional object of custom components to use */
      }
    });
  }

  exports.default = Ember.Helper.helper(contentsToHtml);
});