define('ember-share-db/components/document-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    document: null,
    documentService: Ember.inject.service('documents'),
    store: Ember.inject.service('store'),
    cs: Ember.inject.service('console'),
    sessionAccount: Ember.inject.service('session-account'),
    canEdit: Ember.computed('document', function () {
      return this.get('sessionAccount').currentUserName == this.get('document').owner;
    }),
    doPlay: Ember.computed('document', function () {
      return !this.get('document').dontPlay;
    }),
    index: 0,
    actions: {
      open() {
        this.get('onOpen')(this.get('document').documentId);
      },
      delete() {
        this.get('onDelete')(this.get('document').documentId);
      },
      toggleDontPlay() {
        const docId = this.get('document').documentId;
        this.get('store').findRecord('document', docId).then(doc => {
          const toggled = !doc.data.dontPlay;
          const op = { p: ["dontPlay"], oi: toggled ? "true" : "false" };
          this.get('documentService').submitOp(op, docId);
        }).catch(err => {
          this.get('cs').log("ERROR", err);
        });
      }
    }
  });
});