define('ember-share-db/services/library', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    libraryMap: Ember.computed(() => {
      return [{
        title: "maximilian.js",
        id: "maximilian",
        url: "maximilian.v.0.1.js",
        snippet: `
  <button onclick="start()">Play</button>
  <!-- Maximilian code goes here -->
  <script id = "myAudioScript">
    var osc1 = new Maximilian.maxiOsc();
    function play() {
      return osc1.saw(100) * 0.2
    }
  </script>
  <script language="javascript">
    let start = ()=> {
      let maxi;
      initAudioEngine().then((dspEngine)=>{ 
        maxi = dspEngine;
        //Get audio code from script element
        maxi.setAudioCode("myAudioScript");
      })
    }

    </script>
    `
      }, {
        title: "Maxi Instrument",
        id: "maxiinstrument",
        url: "maxiInstruments.v.0.7.2.js",
        snippet: `
    <button onclick="start()">Play</button>
    <script type="text/javascript">
      let start = ()=> {
        let synth;
        const instruments = new MaxiInstruments();
        instruments.guiElement = document.body
        instruments.loadModules().then(()=> {
          synth = instruments.addSynth();
          var synthSeq = [
            //start, length, pitches
            {s:0, l:4, p:60},
          ];
          synth.setSequence(synthSeq, 4);
          instruments.setLoop(8, 4);

        }).catch((err)=> {
          console.log("error", err);
        });
      }
    </script>
    `
      }, {
        title: "Learner.js",
        id: "learner",
        url: "learner.v.0.5.js",
        snippet: `
  <script type="text/javascript">
    
    ////ML////
    const learner = new Learner();
    learner.addGUI(document.getElementById("dataset"));
    learner.addRegression(2);
 
    ////INPUT////
    //learner.newExample([0,0], learner.y); 
    
    ////OUTPUT////

    //Callback for result
    learner.onOutput = (output)=> {
      console.log(output)
    };

  </script>
    ` }, {
        title: "p5",
        id: "p5",
        url: "https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.7.0/p5.min.js",
        snippet: `
  <script type="text/javascript">
  function setup() {
    createCanvas(640, 480);

  }

  function draw() {
    background(0)
  }
  </script>
    ` }, {
        title: "Thres.js Shaders",
        id: "three-js",
        url: "https://cdnjs.cloudflare.com/ajax/libs/three.js/110/three.min.js",
        snippet: `
<script type="x-shader/x-vertex" id="vertexShader">
        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
  </script>
  <script type="x-shader/x-fragment" id="fragmentShader">
        uniform float time;
        void main() {
            float oscillationR = 0.5 + 0.5 * sin(time);
            float oscillationG = 0.5 + 0.5 * sin(time + 2.0);
            float oscillationB = 0.5 + 0.5 * sin(time + 4.0);
            vec3 color = vec3(oscillationR, oscillationG, oscillationB);
            gl_FragColor = vec4(color, 1.0);
        }
  </script>
  <script>
    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;
    var renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    var geometry = new THREE.BoxGeometry();
    var vertexShader = document.getElementById('vertexShader').textContent;
    var fragmentShader = document.getElementById('fragmentShader').textContent;
    var material = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(0x00ff00) },
        time: { value: 1.0}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader
    });
    var cube = new THREE.Mesh(geometry, material);
    scene.add(cube);
    var startTime = Date.now();
    var animate = function () {
      requestAnimationFrame(animate);
      var currentTime = Date.now() - startTime;
      material.uniforms.time.value = currentTime * 0.001;
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render(scene, camera);
    };
    animate();
  </script>
    ` }, {
        title: "p5 tabbed",
        id: "p5-tabbed",
        url: "https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.7.0/p5.min.js",
        tabSnippet: `
  function setup() {
    createCanvas(640, 480);
  }

  function draw() {
    background(0)
  }
    `,
        tabName: "sketch.js"
      }, {
        title: "Audio Worklets",
        id: "audio-worklets",
        snippet: `
    <script language="javascript" type="text/javascript">
      
    const audioContext = new AudioContext();
    audioContext.audioWorklet.addModule("processor").then(()=> {
      
      const whiteNoiseNode = new AudioWorkletNode(
        audioContext,
        "white-noise-processor"
      );
      whiteNoiseNode.connect(audioContext.destination);
    })

    </script>
      `,
        tabSnippet: `
    class WhiteNoiseProcessor extends AudioWorkletProcessor {
      constructor() {
      super();
      }

      process(inputs, outputs, parameters) {
        const output = outputs[0];
        output.forEach((channel) => {
          for (let i = 0; i < channel.length; i++) {
            channel[i] = (Math.random() * 2 - 1)*0.5;
          }
        });
        return true;
      }
    }

    registerProcessor("white-noise-processor", WhiteNoiseProcessor);
    `,
        tabName: "processor"
      }, { title: "MIMIC Samples", id: "mimicSamples", url: "mimicSamples.js" }, { title: "MMLL", id: "mmll", url: "MMLL.js" }, { title: "RapidLib", id: "rapidlib", url: "rapidLib.js" }, { title: "EvoLib", id: "evolib", url: "evolib.js" }, { title: "Nexus", id: "nexusUI", url: "nexusUI.js" }];
    }),
    libraryEntry(id) {
      let library;
      this.get('libraryMap').forEach(lib => {
        if (lib.id == id) {
          library = lib;
        }
      });
      return library;
    },
    snippet(id) {
      this.libraryEntry(id).snippet;
    },
    url(id) {
      this.libraryEntry(id).url;
    }
  });
});