define('ember-share-db/utils/sorting-handler', ['exports', 'ember-railio-grid/utils/sorting-handler'], function (exports, _sortingHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sortingHandler.default;
    }
  });
});