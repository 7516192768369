define("ember-share-db/routes/guides", ["exports", "ember-share-db/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    guides: Ember.inject.service(),
    cs: Ember.inject.service("console"),
    router: Ember.inject.service(),
    sessionAccount: Ember.inject.service("session-account"),
    activate: function () {
      console.log("activate");
      this._super();
      window.scrollTo(0, 0);
    },
    didTransition() {
      console.log("didTransition");
      window.scrollTo(0, 0);
    },
    async model(params) {
      const guides = this.get("guides").guides;
      this.get("cs").log(guides, params);
      for (let i = 0; i < guides.length; i++) {
        let group = guides[i];
        for (let j = 0; j < group.guides.length; j++) {
          let guide = group.guides[j];
          this.get("cs").log(guide.id == params.topic, guide.id, params.topic);
          //redirect from old urls (guides) to new course pages
          const redirect = {
            "maxi-instrument": "maxiinstruments",
            "maximJS": "making-music",
            "learner": "iml",
            "supervised-ml": "iml"
          };
          if (params.topic in redirect) {
            this.router.transitionTo("course", redirect[params.topic]);
            return;
          }
          if (guide.id == params.topic) {
            this.get("cs").log("Found");
            return guide;
          }
        }
      }

      const courses = await this.get("store").findAll("course");
      console.log("course in model ", courses);
      return { guides, courses };
    }
  });
});