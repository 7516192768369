define('ember-share-db/routes/course/course-overview', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		// setupController(controller, model, transition) {
		// 	this._super(controller, model, transition);

		// }
	});
});