define('ember-share-db/models/account', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { attr, Model } = _emberData.default;

  exports.default = Model.extend({
    username: attr('string'),
    password: attr('string'),
    email: attr('string'),
    created: attr('date'),
    accountId: attr('string')
  });
});